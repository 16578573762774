import {
  deleteUser,
  updateEmail,
  updatePassword,
  updateProfile,
  User,
} from 'firebase/auth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { appStateChanged } from '../data/redux/slices/app';
import {
  toggleHasUserUpdated,
  toggleIsUserUpdating,
} from '../data/redux/slices/login';
import { Person } from '../entities/Person';
import { useAuth } from './auth/useAuth';

export const useMe = () => {
  const { getCurrentUser } = useAuth();
  const user: any = getCurrentUser.currentUser;
  const dispatch = useDispatch();
  const _updateEmail = useCallback(
    async (email: string) => {
      await updateEmail(user, email).then(() => {
        console.log('update email', email);
      });
    },
    []
  );

  const _updatePassword = useCallback(
    async (password: string) => {
      await updatePassword(user, password)
        .then((data) => {
          console.log('update password', password);
        })
        .catch((err) => {
          console.log(
            '===================================='
          );
          console.log(err);
          console.log(
            '===================================='
          );
        });
    },
    []
  );

  const _updateUser = useCallback(
    async ({
      name,
      password,
      email,
    }: Person & { name: string }) => {
      console.log('email', email);

      await updateProfile(user, {
        displayName: name,
      })
        .then((data) => {
          dispatch(toggleHasUserUpdated());
          dispatch(toggleHasUserUpdated());
        })
        .catch((error) => {
          console.log(error);
          dispatch(toggleIsUserUpdating());
        });
      if (password) {
        _updatePassword(password);
        dispatch(toggleIsUserUpdating());
      }
      if (email) {
        _updateEmail(email);
        dispatch(toggleIsUserUpdating());
      }
    },
    []
  );

  const deleteCurrentUser = useCallback(() => {
    deleteUser(user);
  }, []);

  return { _updateUser, user, deleteCurrentUser };
};
