
import React, { useCallback, useEffect,useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  setUser
} from './data/redux/slices/login';

import { useDispatch, useSelector } from 'react-redux';
import { View } from 'react-native';
import { RootState } from './data/redux/store';
import useFBDocs from './hooks/useFBDocs';
import { setMeters,setCurrentMeter } from './data/redux/slices/meter';
import { Portal } from 'react-native-paper';
import { BadgesEarned } from './components/badges-earned';
import { useMeters } from './hooks/use-meters';

const auth = getAuth();
//import { signup, saveUserToFirestore, signout } from '../actions';
function AuthIsLoaded({ children,appIsReady,SplashScreen }) {

  const { user } = useSelector(
    (state: RootState) => state.login
  );  
  const { meters,currentMeter } = useSelector(
    (state: RootState) => state.meter
  );  
  const [initializing, setinitializing] = useState<boolean>(false)
  const [badge, setbadge] = useState<any>();
  const [searchBadge, setsearchBadge] = useState<any>();
  const { addModData } = useMeters();

  const dispatch=useDispatch()
  
  const [searchData, setSearchData] = useState<any>();

  const callback = useCallback((data:any) => {

      console.log('data meters ',data.length);
      if(!currentMeter&&data.length>0){
        dispatch(setCurrentMeter(data[0]))
      }else if(data.length===0){
        dispatch(setCurrentMeter(null))
      }
      dispatch(setMeters(data));
    
  }, []);
  const badgeCallback = useCallback((data:any) => {

      console.log('data badgeCallback ',data.length);
      if(data.length>0){
        console.log('data badgeCallback inner ',data.length);

        if(!data[0].wasShown){
          console.log('data badgeCallback show ',data.length);

          setbadge(data[0])
        }else{

          setbadge(null)
        }
      }else {
        setbadge(null)
      }
      
    
  }, []);

  useFBDocs(searchData, callback);
  useFBDocs(searchBadge, badgeCallback);
  const updateBadge=()=>{
    const {_id}=badge
    setbadge(null)
    addModData(
      {wasShown:true},
      _id,
      `users/<uid>/badges`);
  }
  // Handle user state changes
  function onAuthStateChangedFunc(currentUser) {
    if(currentUser){
      const {uid}=currentUser
      dispatch(setUser({uid}));
      setSearchData({
        path:`users/<uid>/meters`, 
        boolValue:{
          value:'isArchived',
          key:false
        }
      })
      setsearchBadge({
        path:`users/<uid>/badges`, 
        boolValue:{
          value:'wasShown',
          key:false
        }
      })
    }else{
      dispatch(setMeters([]));
      //dispatch(setCurrentMeter(null))

      dispatch(setUser({}));
    }
  }

  useEffect(() => {
    const subscriber = onAuthStateChanged(
      auth,
      onAuthStateChangedFunc
    );
    return subscriber; // unsubscribe on unmount
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady&&initializing) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [appIsReady,initializing]);
  useEffect(() => {
    console.log('init ');
    
    setinitializing(user!==null&&meters!==null)

  }, [user,meters])
  
  if (!appIsReady ||!initializing) {
    return null;
  }
  return (
    <View
      style={{ flex: 1 }}
      onLayout={onLayoutRootView}>
      {children}
      {badge&&<Portal>
        {console.log('badge ',badge)}
        <BadgesEarned
          isEarned={!!badge}
          imageSrc={badge?.imageUrl}
          badgeTitle={badge?.title}
          onConfirm={() => updateBadge()}
          onClose={() => setbadge(null)}
        />
      </Portal>}
    </View>
  );
}

export default AuthIsLoaded;
