import { createSlice } from '@reduxjs/toolkit';

export interface LoginState {
  hasUserUpdated: boolean;
  isUserUpdating: boolean;
  isLoggedIn: boolean;
  isSigningUp: boolean;
  isLoadingIn: boolean;
  initUserState: boolean;
  isResettingPassword: boolean;
  user: any

}

const initialState: LoginState = {
  hasUserUpdated: false,
  isUserUpdating: false,
  isLoggedIn: false,
  isLoadingIn: false,
  isSigningUp: false,
  initUserState: true,
  isResettingPassword: false,
  user: null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
      state.isLoadingIn = false;
    },
    setUser: (state,action) => {
      state.user = action.payload;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.isLoadingIn = false;
    },
    toggleIsLoadingIn: (state) => {
      state.isLoadingIn = !state.isLoadingIn;
    },
    toggleIsSigningUp: (state) => {
      state.isSigningUp = !state.isSigningUp;
    },
    toggleIsResettingPassword: (state) => {
      state.isResettingPassword =
        !state.isResettingPassword;
    },
    toggleIsUserUpdating: (state) => {
      state.isUserUpdating = !state.isUserUpdating;
    },
    toggleHasUserUpdated: (state) => {
      state.hasUserUpdated = !state.hasUserUpdated;
    },
    isNotLoading: (state) => {
      state.isLoadingIn = false;
    },
    isInitUserState: (state) => {
      state.initUserState = false;
    },
  },
});

export const {
  login,
  logOut,
  setUser,
  isNotLoading,
  isInitUserState,
  toggleIsLoadingIn,
  toggleIsSigningUp,
  toggleIsResettingPassword,
  toggleHasUserUpdated,
  toggleIsUserUpdating,
} = loginSlice.actions;
export default loginSlice.reducer;
