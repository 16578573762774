const primaryColor = '#de7636';
const secondaryColor = '#EFB44F';
const tertiaryColor = '#575756';
const coolGray = "rgba(236,236,236,0.01)";
const coolGray2 = "rgb(236,236,232)";

export const colors = {
  primary: primaryColor,
  secondary: secondaryColor,
  tertiary: tertiaryColor,
  coolGray: coolGray,
  coolGray2: coolGray2,
};
