import React from 'react';
import { View } from 'react-native';
export const LayoutWrapper = ({
  children,
  styles,
  ...props
}: {
  styles?: {};
  children: React.ReactNode;
}) => {
  return (
    <View
      style={{ ...styles, backgroundColor: 'white',margin:10 }}
      {...props}>
      {children}
    </View>
  );
};
