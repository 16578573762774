import React,{ memo } from "react";
import { View } from 'react-native';
import { DataTable, Text } from 'react-native-paper';
import { format } from "date-fns";
import CustomIconButton from "./buttons/icon-button";
import { CommonStyles } from "../utils/common-styles";

const DataListView = ({
  entries,generateExcel
}: {
  entries: [];
  generateExcel:(entities:any[],type:number)=>void
}) => {

  return (
    <View
      style={{
        paddingVertical: 10,
      }}>
         <View style={CommonStyles.rowContainer}>
         {entries&&entries.length>0&&<Text variant="titleMedium" style={{
        paddingHorizontal: 10,
      }}>{format(entries[0]?.createdAt?.toDate(),'MMM, yyyy')}</Text>}
            
        <CustomIconButton
          icon='share'
          pressFun={()=>generateExcel(entries,1)}
        />
      </View> 
      
      <DataTable>
        <DataTable.Header>
          <DataTable.Title >
            Date
          </DataTable.Title>
          <DataTable.Title>Time</DataTable.Title>
          <DataTable.Title numeric>Readings</DataTable.Title>
          <DataTable.Title numeric>Top Up</DataTable.Title>
          <DataTable.Title numeric>Consumption</DataTable.Title>
        </DataTable.Header>
        {entries&&entries.map((item,idx)=>(

          <DataTable.Row key={`${item?._id}${idx}`}>
            <DataTable.Cell >
              {format(item?.createdAt?.toDate(),'dd/MM/yyyy')}
            </DataTable.Cell>
            <DataTable.Cell >
              {format(item?.createdAt?.toDate(),'HH:mm')}
            </DataTable.Cell>
            <DataTable.Cell>{item?.meterData}</DataTable.Cell>
            <DataTable.Cell>{item?.lastTopUp}</DataTable.Cell>
            <DataTable.Cell>{item?.diff}</DataTable.Cell>
          </DataTable.Row>
        ))
        }
        
      </DataTable>
    </View>
  );
};

export default memo(DataListView);

