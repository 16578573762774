import { createSlice } from '@reduxjs/toolkit';

const initialState: { badges: any[] } = {
  badges: [],
};

export const badgeSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    badgesChanged: (state,action) => {
        return {...state,badges:action.payload};

    },
  },
});

export const { badgesChanged } = badgeSlice.actions;

export default badgeSlice.reducer;
