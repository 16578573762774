import { memo } from 'react';
import {
    Chart,
    HorizontalAxis,
    Line,
    VerticalAxis,
  } from 'react-native-responsive-linechart';

const ChartComp = ({
    readingsData,xDomain,yDomain,averageReadings
  }: any) => {
    const {min,max}=yDomain
    return (
      <Chart
        {...{ xDomain}}
        yDomain={{min,max:max+50}}
        style={{ height: 200, width: '100%', marginTop: 10 }}
        padding={{ left: 40, bottom: 20, right: 20, top: 5 }}
        //viewport={xDomain.max>15 ?{ size: { width: 15 } }: {}}
        disableGestures
        >
        <VerticalAxis
          tickCount={5}
          theme={{
            axis: { stroke: { color: '#aaa', width: 2 } },
            ticks: { stroke: { color: '#aaa', width: 2 } },
            labels: {
              formatter: (v: number) => v.toFixed(2),
            },
          }}
        />
        <HorizontalAxis
          tickValues={readingsData.map(({x})=>x)}
          //tickCount={readingsData.length}
          theme={{
            axis: { stroke: { color: '#aaa', width: 2 } },
            ticks: { stroke: { color: '#aaa', width: 2 } },
            labels: {
              label: { rotation: 0 },
            },
          }}
        />
        {readingsData&&<Line
          data={readingsData}
          smoothing='bezier'
          theme={{ stroke: { color: '#e26a00', width: 4 },scatter: { default: { width: 8, height: 8, rx: 4, color: '#e26a00' }} }}
        />}
          {averageReadings&&<Line
            data={averageReadings}
            smoothing="bezier"
            theme={{ stroke: { color: "blue", width: 1.5 },scatter: { default: { width: 4, height: 4, rx: 2, color: 'blue' }} }}
          />}
      </Chart>
    );
  };

  export default memo(ChartComp)