import * as React from 'react';
import {
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';

import MeterApp from '../screens/meters';
import { ReadingsStack } from './readings-stack';
import { colors } from '../theme/colors';
import { HomeStack } from './home-stack';
import Profile from '../screens/account/profile';

const Tab = createMaterialBottomTabNavigator();

const AppBottomNavigation = () => {

  return (
    <Tab.Navigator
        initialRouteName="Home"
        barStyle={{ backgroundColor: '#fff', height: 70 }}
        activeColor={colors.primary}
        shifting={false}
        backBehavior="history"
      >
        <Tab.Screen
          options={{
            tabBarIcon: ({ color }) => (
              <MaterialCommunityIcons
                name="home-outline"
                color={color}
                size={20}
              />
            ),
          }}
          name="Home"
          component={HomeStack}
        />
        <Tab.Screen
          options={{
            tabBarIcon: ({ color }) => (
              <MaterialCommunityIcons
                name="book-outline"
                color={color}
                size={20}
              />
            ),
          }}
          name="Meters"
          component={MeterApp}
        />
        
        <Tab.Screen
          options={{
            tabBarIcon: ({ color }) => (
              <MaterialCommunityIcons name="history" color={color} size={20} />
            ),
          }}
          name="Readings"
          component={ReadingsStack}
        />
        <Tab.Screen
          options={{
            tabBarIcon: ({ color }) => (
              <MaterialCommunityIcons
                name="account-outline"
                size={20}
                color={color}
              />
            ),
          }}
          name="Profile"
          component={Profile}
        />
      </Tab.Navigator>
  );
};

export default AppBottomNavigation;
