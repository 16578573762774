import React from 'react';
import { Provider as PaperProvider } from 'react-native-paper';
import { Provider } from 'react-redux';
import AppContainer from './AppStack';
import { store } from './data/redux/store';

import { theme } from './theme/theme';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { StatusBar } from 'react-native';
import { colors } from './theme/colors';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/lib/persistStore';
import * as SplashScreen from 'expo-splash-screen';

import * as Font from 'expo-font';
import { Asset } from 'expo-asset';
import { MaterialCommunityIcons,FontAwesome5 } from '@expo/vector-icons';
SplashScreen.preventAutoHideAsync();
const persistedStore = persistStore(store)
export default function App() {
  const [appIsReady, setAppIsReady] = React.useState(false);

  function cacheImages(images) {
    return images.map((image) => {
      if (typeof image === 'string') {
        return Image.prefetch(image);
      }
      return Asset.fromModule(image).downloadAsync();
    });
  }

  const cacheFonts=(fonts) =>{

    return fonts.map((font) => Font.loadAsync(font));
  }

  const _loadAssetsAsync = async () => {
    const imageAssets = cacheImages([
      require('./assets/adaptive-icon.png'),
      require('./assets/favicon.png'),
      require('./assets/icon.png'),
      require('./assets/splash.png'),
      require('./assets/logo.png'),
    ]);

    const fontAssets = cacheFonts([
      MaterialCommunityIcons.font,
      FontAwesome5.font,
    ]);

    await Promise.all([ ...fontAssets,...imageAssets]);

  };
  React.useEffect(() => {
    async function prepare() {
      try {
        // Keep the splash screen visible while we fetch resources
        // Pre-load fonts, make any API calls you need to do here
        await _loadAssetsAsync()
        // Artificially delay for two seconds to simulate a slow loading
        // experience. Please remove this if you copy and paste the code!
        await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setAppIsReady(true);
      }
    }

    prepare();
  }, []);


  if (!appIsReady) {
    return null;
  }
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore} loading={null}>
        <SafeAreaProvider>
          <PaperProvider theme={theme}>
            <StatusBar animated={true} backgroundColor={colors.primary} />
            <AppContainer {...{appIsReady,SplashScreen}}/>
          </PaperProvider>
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  );
}
