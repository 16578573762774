import React,{memo} from 'react';
import { StyleSheet } from 'react-native';
import {
  Button,
  Dialog,
  Paragraph,
  Portal,
} from 'react-native-paper';

const ConfirmationModal = ({
  title,
  massage,
  onConfirm,
  onConfirmText,
  onDismissText,
  visible,
  hasCancel,
  warnMassage,
  onDismiss,
}: {
  title: string;
  massage?: string;
  warnMassage?: string;
  onConfirmText?: string;
  onDismissText?: string;
  visible: boolean;
  hasCancel?: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Portal>
      <Dialog visible={visible} onDismiss={onDismiss}>
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Content>
          {warnMassage&&<Paragraph style={styles.warnMassage}>
            {warnMassage}
          </Paragraph>}
          {massage&&<Paragraph>{massage}</Paragraph>}
        </Dialog.Content>
        <Dialog.Actions>
          {hasCancel ? (
            <Button onPress={onDismiss}>
              { onDismissText || 'Cancel'}
            </Button>
          ) : null}
          <Button onPress={onConfirm}>
            {onConfirmText || 'Done'}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
  }
export default memo(ConfirmationModal)
const styles = StyleSheet.create({
  warnMassage: {
    color: 'red',
  },
});
