
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import React from 'react';

import { useSelector } from 'react-redux';
import { CustomFAB } from '../../components/buttons/custom-fab';
import Prompts from '../../components/prompts';
import { ScreenTitleWrapper } from '../../components/screen-title-wrapper';
import { RootState } from '../../data/redux/store';
import { colors } from '../../theme/colors';
import { addMeterDefaultValues } from './constants';

import EditMeter from './edit-meter';
import MeterListView from './meter-list-view';

const Stack = createNativeStackNavigator();

const MeterView = ({navigation}) => {
  const { meters } = useSelector(
    (state: RootState) => state.meter
  ); 
 
  return (
    <>
      <ScreenTitleWrapper title='Meters'>
        
        
        {meters&&meters?.length > 0 ? (
          <MeterListView
            {...{navigation,meters}}
            
          />
        ) : (
          <Prompts
            title='Step 1'
            step1='Create a meter here.'
            height='100%'
            nav1={{name:'AddMeter'}}
          />
        )}
        
      </ScreenTitleWrapper>
      <CustomFAB
      icon='plus'
      color='white'
      bg={colors.primary}

        onPress={() => {
          navigation.navigate('AddMeter',addMeterDefaultValues)
        }}
      />
    </>
  );
};

const MeterApp = () => (
    <Stack.Navigator
      //  initialRouteName="MetersView"
      screenOptions={{
        headerShown: false,
        contentStyle: {
          backgroundColor: 'white',
        },
      }}>
      <Stack.Screen
        options={{}}
        name='MetersView'
        component={MeterView}
      />
      <Stack.Screen
        options={{}}
        name='EditMeter'
        component={EditMeter}
      />
      <Stack.Screen
        options={{}}
        name='AddMeter'
        component={EditMeter}
      />
    </Stack.Navigator>
);

export default MeterApp;
