import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import EnergyGraph,{  GraphState } from '../components/energy-graph';
import { ScreenTitleWrapper } from '../components/screen-title-wrapper';
import { LayoutWrapper } from '../components/layout-wrapper';
import useFBDocs from '../hooks/useFBDocs';
import { LoadingComp } from '../components/loading-comp';
import { flatten,startCase,isEmpty,orderBy,uniqBy,round,isNaN } from "lodash";
import { Modal,Portal, Text } from 'react-native-paper';
import { setCurrentMeter } from '../data/redux/slices/meter';

//import meter from '../data/redux/slices/meter';
import CustomIconButton from '../components/buttons/icon-button';
import DatePicker from 'react-native-modern-datepicker';
import { isBefore,isAfter,isThisMonth, lastDayOfMonth,addMonths,subMonths,format, isSameMonth } from "date-fns";
import Toast from 'react-native-toast-message';
import FirebaseApp from '../config/firebase';
import { getAuth } from 'firebase/auth';
import Prompts from '../components/prompts';
import { RootState } from '../data/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setaverageReadings,setmeterReadings,setsubTitle ,setDate,setNum } from '../data/redux/slices/graph';
import { addMeterDefaultValues } from './meters/constants';


const firebaseAuth = getAuth(FirebaseApp); 

const colPath=[
  'averages/<$>/records',
  'averages/<$>/regions/<$$>/records',
  'averages/<$>/regions/<$$>/towns/<$$$>/records',
]

export function Home({navigation}:any) {
  // Get all meters and show first meter
  const [currentReports, setcurrentReports] =useState<any>([]);
  const [currentMark, setcurrentMark] =useState<any>([]);
  const { meterReadings,averageReadings, subTitle,date,num} = useSelector(
    (state: RootState) => state.graph
  );
  const dispatch=useDispatch()

  const { meters,currentMeter } = useSelector(
    (state: RootState) => state.meter
  ); 
  const reform = useRef<any>();
  const mounted = useRef<boolean>(false);
  const [searchReadings, setSearchReadings] = useState<any>();
  const [searchBenchmark, setSearchBenchmark] = useState<any>();
  
  const [isSpinner, setSpinner] = useState<boolean>(meterReadings!==null||(meterReadings&&meterReadings.length===0));
  const [msg, setmsg] = useState<any>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const containerStyle = {backgroundColor: 'white', padding: 20};
  const currentDate=new Date()
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const startDate=useMemo(()=>firebaseAuth.currentUser?.metadata.creationTime,[firebaseAuth.currentUser?.metadata.creationTime])
  const benchMarkPath=(number:number)=>{
    dispatch(setNum(number))
  }
  const beforeDate=(selectedDate:Date)=>{  
    return isBefore(selectedDate, new Date(startDate))  
  }
  const afterDate=(selectedDate:Date)=>{
    return isAfter(selectedDate, currentDate)
  }
  const selDate=(newDateStr:Date,hide?:boolean)=>{
    if(
      !isThisMonth(newDateStr)&&
      (beforeDate(newDateStr)||
      afterDate(newDateStr))
    ){
      const text2=hide?'No records for this period':'No records beyond current selection'
      setmsg(text2)
      Toast.show({
        type: 'error',
        text1: 'No records',
        text2
      });
    }else{
      setmsg(null)
      console.log('set period ',newDateStr);

      dispatch(setDate(format(newDateStr,'MM/dd/yyyy')))
      if(hide){

        hideModal()
      }
    }
  }
  const getCurDate=()=>{
    let newDateStr
    if(date){

      const splitDateStr=date.split('/')
      const lastDay=lastDayOfMonth(new Date(Number(splitDateStr[2]),Number(splitDateStr[0])-1,1)).getDate()
  
       newDateStr= new Date(Number(splitDateStr[2]),Number(splitDateStr[0])-1,lastDay)
      
    }else{
      newDateStr=new Date()
    }
    return newDateStr
        
  }
  const dataNav=(firstCol:string)=>{
    let params={meterId:currentMeter?._id,meterName:currentMeter?.name}
     params=subTitle!=='daily'?{...params,summary:{payload:meterReadings,type:0},date,firstCol,search:{...reform.current,path:`users/<uid>/daily`}}:{...params,date,firstCol,summary:{payload:meterReadings,type:0},currentReports:{payload:currentReports,type:1}}
    navigation.navigate('DataView',params)
  }
  const readingCallback = useCallback((data:any, posts:any,col:string) => {

    console.log('isCompelete total outer ');
    if(mounted.current){

      console.log('isCompelete total ', data, 'job_posts ', col,' date ',date);
      setSpinner(false);
      setcurrentReports(null);
      if(data.length>0){
        if(col==='daily'){
          const dailyData=data[0]
      console.log('isCompelete daily ', data, 'job_posts ', col,' date ',date);

          setcurrentReports([dailyData]);

        }else{
          //console.log('isCompelete total outer ', data, 'job_posts ', col,' date ',date);

          setcurrentReports(data);

        }
      }else{
        setcurrentReports(data);

      }
  
    }
  }, []);
  useFBDocs(searchReadings, readingCallback);
  const markCallback = useCallback((data:any, posts:any,col:string) => {

    if(mounted.current){

      setcurrentMark(null);
      setcurrentMark(data);

  
    }
  }, []);
  useFBDocs(searchBenchmark, markCallback);
  const startSearch = useCallback((form:any, posts:any, lastDoc:any) => {
    console.log('startSearch useCallback ', lastDoc);

    setSearchReadings(null)
    setSearchReadings({
      ...form,
      posts,
      lastDoc,
    });
  }, []);
 
  
  useEffect(() => {

    if(currentMeter&&currentReports){
      //console.log('selMeter currentReports ',currentReports);
      if(isEmpty(currentReports)){
         dispatch(setmeterReadings(null))
      }else{

        const {total,items}=currentReports.reduce((cal:any,val:any)=>{
          const payload=val[currentMeter._id]
          if(payload){
            const {aveTotal,entries}=payload
            return {...cal,total:cal.total+aveTotal,items:[...cal.items,entries]}
          }else{
            return cal
          }
          
        },{total:0,items:[]})
        let average=0
        let flatRes=null
        if(!isEmpty(items)&&!items.includes(undefined)){
          const allItems=orderBy(flatten(items),['dx'],['asc']).filter(({gen})=>!gen)
          //console.log('selMeter allItems ',allItems);
          const sizeBool=allItems.length===1
          flatRes=uniqBy(allItems,'dx').reduce((cal:any,val:any)=>{
            if(val){
           const {diff,name,dx,count}=val
           
           const {readingsData,xDomain:{min, max},yDomain:{ max: yMax },labels}=cal
           const len=readingsData.length
           if(len>0&&readingsData[len-1].x>dx){
              return cal
            }else{
              const checkItem=readingsData.findIndex((val)=>val.x===dx)
              if(checkItem!==-1){
                readingsData[checkItem] = {x:dx,y:diff};
  
              }else{
                if(subTitle!=='daily'&&count&&diff){
                  
                  average+=diff
                }
                readingsData.push({x:dx,y:diff})
              }
              return {
                readingsData,
                labels:[...labels,name], 
                yDomain:{ min:0, max: yMax<diff?diff:yMax },
                xLabel:name,
                xDomain:{ min:min===0?dx:min, max: max<dx&&!sizeBool?dx+7:max<dx&&sizeBool?dx+7:max },
                ave:0
              }
            }
          }else{
            return cal
          }
         },{readingsData:[],xDomain:{min: 0, max: 7},yDomain:{ min: 0, max: 20 },labels:[]})
        }
        console.log('flatRes ',flatRes,' average ',average,' ||total ',total);
        if(!date&&flatRes){
  
          dispatch(setDate(flatRes?.xLabel))
        }
        if(flatRes){
          
  
          dispatch(setmeterReadings({...flatRes,average:`${round((average||total),2)}`}))
        }else{
          dispatch(setmeterReadings(flatRes))
  
        }
      }
      //const readingsData=
    }
  }, [currentMeter,currentReports])
  useEffect(() => {

    if(currentMeter&&currentMark){
      try {
      
      const items=currentMark.map((val:any)=>val[currentMeter.categoryId].entries)

      ///console.log('setaverageReadings currentMark ',currentMark,items);
     
      let flatRes=null
      if(!isEmpty(items)&&!items.includes(undefined)){
        const allItems=orderBy(flatten(items),['dx'],['asc']).filter(({gen})=>!gen)
        ///console.log('selMeter allItems ',allItems);
        flatRes=uniqBy(allItems,'dx').reduce((cal:any,val:any)=>{
          if(val){
         const {ave,count,dx}=val
         
         const len=cal.length
         if(len>0&&cal[len-1].x>dx){
            return cal
          }else{
            const checkItem=cal.findIndex((val)=>val.x===dx)
            let y=ave/count
           // console.log('isNaN ave ',ave,' count ',count);

            y=!isNaN(y)&&y!==Infinity?y:0
           // console.log('isNaN ',y);
            
            if(checkItem!==-1){
              cal[checkItem] = {x:dx,y:round(y, 2)};

            }else{
              
              cal.push({x:dx,y:round(y, 2)})
            }
            return cal
          }
        }else{
          return cal
        }
       },[])
      }
      //console.log('setaverageReadings flatRes ',flatRes);
      

        dispatch(setaverageReadings(flatRes))
      } catch (error) {
        dispatch(setaverageReadings(null))

      }
      //const readingsData=
    } 
  }, [currentMeter,currentMark])

  useEffect(() => {
    mounted.current=true

    if(currentMeter&&date){
      const {location,townId,countryId}=currentMeter
      const splitDateStr=date.split('/')
        const newDateStr= new Date(Number(splitDateStr[2]),Number(splitDateStr[0])-1,Number(splitDateStr[1]))
        const date2=addMonths(newDateStr,1).setDate(1)
      setSearchBenchmark({
        path:colPath[num]
        .replace('<$>', countryId)
        .replace('<$$>', location)
        .replace('<$$$>', townId)
        .replace('records', subTitle),

        date2,
        direction:subTitle==='daily'?'desc':'asc',
        lim:subTitle==='daily'?1:12
      })
    }
    return ()=> {
      mounted.current=false
    }
  }, [currentMeter,date,num,subTitle])
  useEffect(() => {
      console.log('date ',date,' meters ',meters?.length);
      
    if(meters&&meters.length>0){
      
      let dataSet:any={
        path:`users/<uid>/${subTitle}`,
        
      }
      
      if((!meterReadings&&date)||(meterReadings&&date&&date!==meterReadings?.xLabel)){
        console.log('date inner ',date,' meters ',meters?.length);

        const splitDateStr=date.split('/')
        const newDateStr= new Date(Number(splitDateStr[2]),Number(splitDateStr[0])-1,Number(splitDateStr[1]))


        const date2=addMonths(newDateStr,1).setDate(1)
        dataSet={
          ...dataSet, 
          date2,
          direction:subTitle==='daily'?'desc':'asc',
          lim:subTitle==='daily'?1:12
        }
        setSpinner(true);

      }else{
        dataSet={
          ...dataSet, 
          direction:subTitle==='daily'?'desc':'asc',
          lim:subTitle==='daily'?1:12
        }
      }  
      reform.current = dataSet;
      startSearch(dataSet, [],null);
    }else{
      setSpinner(false)
    }
      
  }, [date,meters,subTitle])

  
  
  if (isSpinner) {
    return <LoadingComp />;
  }
 
  return (
    <>
        <LayoutWrapper>
       <ScreenTitleWrapper
        title='Consumption'
        subTitle={startCase(subTitle)}
        >
          {meters.length >0? (
            <View>
              <Dropdown
                placeholder='Select Meter'
                style={styles.dropdown}
                containerStyle={styles.dropdownContainer}
                itemContainerStyle={
                  styles.dropdownItemContainer
                }
                onChange={(item) => {
                  ///dispatch(setCurrentMeter(item));
                  dispatch(setCurrentMeter(item))
                }}
                data={meters}
                valueField='name'
                labelField='name'
                value={currentMeter?.name}
              />
               {date&&<View style={styles.container}>

                <CustomIconButton
                  icon='chevron-left-circle-outline'
                  disabled={isSpinner}
                  pressFun={()=>{
                    /* navigation.navigate('Readings') */
                    const newDateStr=getCurDate()
                    const newDate=subMonths(new Date(newDateStr),1)
                    selDate(newDate)
                  }}
                />
                <Pressable
                onPress={()=>{
                  setmsg(null)

                  showModal()
                }}
                >
                  <Text
                    style={{
                      color: '#86C5F6',
                      fontSize: 18,
                    }}>
                    {format(new Date(getCurDate()),'MMM, yyyy')} 
                  </Text>
                </Pressable>
                <CustomIconButton
                  icon='chevron-right-circle-outline'
                  disabled={isSpinner}
                  pressFun={()=>{
                    const newDateStr=getCurDate()
                    const newDate=addMonths(new Date(newDateStr),1)
                    selDate(newDate)
                  }}
                />
                </View>} 
              {meterReadings ? (
                <EnergyGraph
                  {...{meterReadings,graphState:subTitle,averageReadings,benchMarkPath,dataNav}}
                  graphNavigationHandler={(
                    item: GraphState
                  ) => {
                    dispatch(setsubTitle(item));
                  }}
                />
              ) : (<Prompts
                      title='No data for this meter'
                      step1='Click Here to Add meter readings.'
                      height='60%'
                      nav1={{name:'Readings',screen:'AddReadings'}}
                    />
            )}
          </View>
        ) : (<Prompts
              title='Get Started!'
              step1='Step 1: Create meter on the meter tab.'
              step2='Step 2: Add meter readings here.'
              height='100%'
              nav1={{name:'Meters',screen:'AddMeter',params:addMeterDefaultValues}}

            />
      )}
  </ScreenTitleWrapper>
    </LayoutWrapper>
    <Portal>
          <Toast/>
      <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
      {msg&&<Text
          style={{
            color: 'red',
            fontSize: 10,
            marginBottom: 20,
            alignSelf:'center'
          }}>
          {msg} 
        </Text>}
      <DatePicker
      mode="monthYear"
      selectorStartingYear={new Date(startDate).getFullYear()}
      selectorEndingYear={currentDate.getFullYear()}
      onMonthYearChange={(selectedDate:string) => {
        const splitDateStr=selectedDate.split(' ')
        const lastDay=lastDayOfMonth(new Date(Number(splitDateStr[0]),Number(splitDateStr[1])-1,1)).getDate()
        const newDateStr=new Date(Number(splitDateStr[0]),Number(splitDateStr[1])-1,lastDay)
        console.log('onMonthYearChange ',selectedDate,' newDateStr ',newDateStr);
        selDate(newDateStr,true)
      }}
      
    />
    
        </Modal>
        </Portal>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 10,
  },
  dropdown: {
    width: '70%',
    alignSelf: 'center',
    borderColor: '#000',
    borderWidth: 1,
    paddingVertical: 5,
    borderRadius: 4,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
  dropdownContainer: {
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: 4,
  },
  dropdownItemContainer: {
    borderTopWidth: 1,
    borderColor: 'lightgrey',
    borderRadius: 4,
  },
});

export default Home;
