import { configureStore,combineReducers } from '@reduxjs/toolkit';
import loginReducer from './slices/login';
import meterReducer from './slices/meter';
import appReducer from './slices/app';
import badgeReducer from './slices/badges';
import graphReducer from './slices/graph';
import { persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';


const persistConfig = {
  key: 'root',
  version:3,
  storage:AsyncStorage,
};
const reducers=combineReducers({
  login: loginReducer,
  meter: meterReducer,
  app: appReducer,
  badge: badgeReducer,
  graph: graphReducer,
})
const persistedReducer=persistReducer(persistConfig,reducers)
export const store = configureStore({
  reducer: persistedReducer
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDespatch = typeof store.dispatch;
