import React from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { Text, TextInput } from 'react-native-paper';
import { AddreadingType } from './types';

export const AddReadingForm = ({
  control,
  styles,
  errors,
  watch,
}: AddreadingType) => {
  const reading = control._formValues.meterData;
  const topUp = watch('lastTopUp');
  
  return (
      <View
        style={{
          marginBottom: 10,
        }}>
        <Controller
          control={control}
          rules={{
            required: true,
            minLength: 0,
            // value:
            validate: () => {
              return Number(reading) >= Number(topUp);
            },
          }}
          render={({ field: { onChange, value } }) => (
            <TextInput
              mode='outlined'
              label={'Top up (kWh)'}

              placeholder='Top up (kWh)'
              style={styles.input}
              value={value}
              keyboardType='numeric'
              onChangeText={onChange}
            />
          )}
          name='lastTopUp'
        />
        {errors.lastTopUp?.type === 'required' ? (
          <Text style={styles.errorText}>
            Meter Data is required.
          </Text>
        ) : errors.lastTopUp?.type === 'minLength' ? (
          <Text style={styles.errorText}>
            Meter Nam should be a min of 1 unit .
          </Text>
        ) : (
          errors.lastTopUp?.type === 'validate' && (
            <Text style={styles.errorText}>
              TopUp should be less or equal to the
              current reading
            </Text>
          )
        )}
      </View>
  );
};
