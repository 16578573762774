import React from 'react';
import { Pressable, PressableProps, View } from 'react-native';
import { CheckboxProps, Text } from 'react-native-paper';
import { colors } from '../theme/colors';

type CustomCheckBoxProps =  PressableProps & {
  text: string
  status: Pick<CheckboxProps, 'status'> | string
  }
  
export const Checkbox = (props: CustomCheckBoxProps) => {
  const { text, status } = props;
  const isChecked = Boolean(
    status === 'checked'
      ? true
      : status === 'unchecked'
      ? false
      : false
  );
  return (
    <Pressable
      style={{
        flexDirection: 'row',
        alignContent: 'center',
        marginBottom: 20,}}
      
      {...props}>
      <View
        style={{
          borderColor: isChecked ? colors.primary : undefined,
          borderWidth: 1,
          padding: 3,
          marginRight: 5,
          borderRadius: 3,
          alignSelf: 'flex-start',
        }}>
        <View
          style={{
            backgroundColor: isChecked
              ? colors.primary
              : undefined,
            borderRadius: 3,

            width: 16,
            height: 16,
          }}
        />
      </View>
      <Text
        style={{
          color: isChecked ? colors.primary : undefined,
          alignSelf: 'center',
          fontSize: 16,
        }}>
        {text}
      </Text>
    </Pressable>
  );
};
