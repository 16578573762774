import React from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { List } from 'react-native-paper';
import { LayoutWrapper } from '../components/layout-wrapper';
import { ScreenTitleWrapper } from '../components/screen-title-wrapper';
import { colors } from '../theme/colors';

const FAQ = () => {
  return (
    <ScreenTitleWrapper
      title='FAQ'
      subTitle={'Get Answers'}>
      <LayoutWrapper>
        <ScrollView
          style={{
            marginHorizontal: 10,
          }}>
          <View
            style={{
              paddingBottom: 100,
            }}>
            <List.Section
              titleStyle={{
                color: colors.primary,
                fontWeight: 'bold',
                fontSize: 25,
              }}
              title='How to use the app'>
              <List.Accordion title='How to get started'>
                <List.Item
                  title='1. Add a meter'
                  description='Go to the meters tabs and click on the + icon, fill your meter information.'
                />
                <List.Item
                  title='2. Add readings'
                  description='Go to the readings and click on the + icon and fill your meter reading.'
                />
                <List.Item
                  title='3. Select meter to see your consumption.'
                  description='You can see you consumption after you have added a meter and readings.'
                />
              </List.Accordion>

              <List.Accordion title='How to earn a badge?'>
                <List.Item
                  title='First Meter'
                  description='You can earn this badge if you add your first meter.'
                />
                <List.Item
                  title='10 Readings'
                  description='You can earn this badge if you add 10 readings to your meter.'
                  descriptionNumberOfLines={5}
                />
                <List.Item
                  title='Period Badges'
                  description='You can earn these badges if you use the app over a period of 1 month, 2 months, 3 months, 6 months , 9 months, 12 months. e.t.c.'
                  descriptionNumberOfLines={5}
                />
              </List.Accordion>
              <List.Accordion title='Why earn badges?'>
                <List.Item
                  title
                  description='Earned badges help to show your progress and
            app usage.'
                  descriptionNumberOfLines={5}
                />
              </List.Accordion>
              <List.Accordion
                title='How can I track my consumption easily?'
                titleNumberOfLines={2}>
                <List.Item
                  style={{
                    flexWrap: 'wrap',
                  }}
                  description='You can track your consumption easily
              if you take your readings regularly.'
                  descriptionNumberOfLines={12}
                  title={null}
                />
              </List.Accordion>
            </List.Section>
          </View>
        </ScrollView>
      </LayoutWrapper>
    </ScreenTitleWrapper>
  );
};
export default FAQ;
