import React from 'react'
import { View,FlatList } from 'react-native';
import { DataTable } from 'react-native-paper';


const MeterListView = ({
  meters,
  navigation,
}: {
  meters: [];
  navigation: any;
}) => {

  return (
    <View
      style={{
        paddingTop: 20,
        height: '86%',
        paddingBottom: "28%",
      }}>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>Meter Name</DataTable.Title>
          <DataTable.Title numeric>
            Created At
          </DataTable.Title>
        </DataTable.Header>
        <FlatList
                  data={meters}
                  keyExtractor={(item) => {
                    return item._id;
                  }}
                  extraData={{ meters }}
                  snapToAlignment="center"
                  renderItem={({item})=><DataTable.Row
                    onPress={() => {
                      navigation.navigate('EditMeter', item);
                    }}>
                    <DataTable.Cell>{item?.name}</DataTable.Cell>
                    <DataTable.Cell numeric>
                      {new Date(item?.createdAt).toDateString()}
                    </DataTable.Cell>
                  </DataTable.Row>
                 }
                />
        
      </DataTable>
    </View>
  );
};

export default MeterListView;
