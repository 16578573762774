import { View } from 'react-native';
import { Avatar } from 'react-native-paper';
import Icon from 'react-native-vector-icons/FontAwesome';
import React from 'react';
import { colors } from '../theme/colors';

export const AvatarIcon = () => {
  return (
    <View>
      <Avatar.Icon
        size={80}
        style={{
          alignSelf: 'center',
          backgroundColor: 'white',
          marginBottom: 10,
        }}
        icon={() => (
          <Icon
            name='user-circle'
            color={colors.primary}
            size={80}
            style={{
              alignSelf: 'center',
            }}
          />
        )}
      />
    </View>
  );
};
