import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import { NavEntity } from '../entities/NavEntity'
import { colors } from '../theme/colors'

const Prompts = ({title,step1,step2,height,nav1,nav2}:{
  title:string;
  step1?:string;
  step2?:string,
  nav1?:NavEntity,
  nav2?:NavEntity,
  height:string}) => {
    console.log('title ',title,' step1 ',step1);
    const navigation=useNavigation()
    const navToScreen=(nav:NavEntity)=>{
      const {name,screen,params}=nav
      if(screen){

        navigation.navigate(name,{screen,
          initial: false,params
        })
      }else{

        navigation.navigate(name)
      }
    }
  return (
    <View
          style={{
            height,
            width: '80%',
            alignSelf: 'center',
            justifyContent: 'center',
          }}>
          <Text
            style={{
              textAlign: 'center',
              color: colors.primary,
              fontWeight: 'bold',
              fontSize: 25,
              marginBottom: 10,
            }}>
            {title}
          </Text>
          {step1&&<Text
            onPress={()=>{
              if(nav1){
                navToScreen(nav1)
              }
            }}
            style={{
              color: colors.secondary,
              fontWeight: 'bold',
              fontSize: 18,
            }}>
            {step1}
          </Text>}
          {step2&&<Text
            onPress={()=>{
              if(nav2){
                navToScreen(nav2)
              }
            }}
            style={{
              color: colors.secondary,
              fontWeight: 'bold',
              fontSize: 18,
            }}>
            {step2}
          </Text>}
        </View>
  )
}

export default Prompts