import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItem,
} from '@react-navigation/drawer';
import {
  DrawerActions,
  useNavigation,
} from '@react-navigation/native';
import React from 'react';

import {
  Image,
  ImageBackground,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  Divider,
  Title,
} from 'react-native-paper';
import { useAuth } from '../hooks/auth/useAuth';
import Badges from '../screens/badges';
import FAQ from '../screens/FAQ';
import Tips from '../screens/tips';
import { colors } from '../theme/colors';
import AppBottomNavigation from './tabs';
import { FontAwesome5 } from '@expo/vector-icons';

const Drawer = createDrawerNavigator();
export const AppStack = () => {
  const navigate = useNavigation();
  const { _logOut } = useAuth();

  return (
    <>
      <Drawer.Navigator
        screenOptions={{
          // headerShown: false,
          headerStyle: {
            backgroundColor: 'transparent',
          },
          drawerStyle: {
            backgroundColor: colors.coolGray2,
          },
          // headerTransparent: true,

          headerTitle: () => {
            return (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <Image
                  style={{
                    backgroundColor: 'transparent',
                    width: 50,
                    height: 50,
                    alignSelf: 'center',
                  }}
                  source={require('../assets/logo.png')}
                />
                <Title
                  style={{
                    borderColor: colors.secondary,
                    backgroundColor: 'transparent',
                    alignSelf: 'center',
                  }}>
                  Energy Efficiency
                </Title>
              </View>
            );
          },
          headerLeft: () => false,
          headerRight: () => {
            return (
              <TouchableOpacity
                style={{
                  padding: 20,
                  // marginTop: -19,
                  alignSelf: 'flex-end',
                }}
                onPress={() => {
                  navigate.dispatch(
                    DrawerActions.openDrawer()
                  );
                }}>
                <FontAwesome5 size={14} name='ellipsis-v' />
              </TouchableOpacity>
            );
          },
        }}
        drawerContent={(props) => (
          <DrawerContentScrollView
            {...props}
            style={{
              height: 1000,
            }}>
            <View
              style={{
                flex: 1,
                backgroundColor: colors.coolGray2,
              }}>
              <Title
                style={{
                  borderRadius: 10,
                  borderWidth: 0.2,
                  borderColor: colors.secondary,
                  backgroundColor: 'transparent',
                  alignSelf: 'center',
                  marginTop: 5,
                }}>
                Energy Efficiency
              </Title>
              <Image
                style={{
                  borderRadius: 10,
                  borderWidth: 0.2,
                  // borderColor: colors.secondary,
                  backgroundColor: 'transparent',
                  width: 150,
                  height: 150,
                  alignSelf: 'center',
                  margin: 10,
                }}
                source={require('../assets/logo.png')}
              />
            </View>
            <Divider
              style={{
                backgroundColor: 'red',
              }}
            />

            {/* <DrawerItemList {...props} /> */}
            <DrawerItem
              style={{
                zIndex: 10000,
              }}
              icon={() => <FontAwesome5 size={28} name='home' />}
              onPress={() => {
                navigate.navigate('HomeStack');
              }}
              label='Home'
            />
            <DrawerItem
              style={{
                zIndex: 10000,
              }}
              icon={() => (
                <FontAwesome5 size={28} name='certificate' />
              )}
              onPress={() => {
                navigate.navigate('Badges');
              }}
              label='Badges'
            />
            <DrawerItem
              style={{
                zIndex: 10000,
              }}
              icon={() => (
                <FontAwesome5 size={28} name='info-circle' />
              )}
              onPress={() => {
                navigate.navigate('Tips');
              }}
              label='Tips'
            />
            <DrawerItem
              style={{
                zIndex: 10000,
              }}
              icon={() => (
                <FontAwesome5 size={28} name='question-circle' />
              )}
              onPress={() => {
                navigate.navigate('FAQ');
              }}
              label='FAQ'
            />
            <DrawerItem
              style={{
                zIndex: 10000,
              }}
              icon={() => (
                <FontAwesome5 size={28} name='sign-out-alt' />
              )}
              onPress={_logOut}
              label='Logout'
            />
            {Array(2)
              .fill(2)
              .map((i, index) => (
                <ImageBackground
                  key={index}
                  resizeMode='repeat'
                  style={{
                    borderRadius: 10,
                    borderWidth: 5,
                    opacity: 0.04,
                    height: 1000,
                    width: '100%',
                    position: 'absolute',
                  }}
                  source={require('../assets/images/energy-span.png')}
                />
              ))}
          </DrawerContentScrollView>
        )}
        backBehavior='firstRoute'
        initialRouteName='AppBottomNavigation'
        useLegacyImplementation={true}>
        <Drawer.Screen
          options={{
            title: '',
          }}
          name='HomeStack'
          component={AppBottomNavigation}
        />
        <Drawer.Screen
          options={{
            title: 'Badges',
          }}
          name='Badges'
          component={Badges}
        />
        <Drawer.Screen
          options={{
            title: 'Tips',
          }}
          name='Tips'
          component={Tips}
        />
        <Drawer.Screen
          options={{
            title: 'FAQ',
          }}
          name='FAQ'
          component={FAQ}
        />
      </Drawer.Navigator>
    </>
  );
};
