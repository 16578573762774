import React from 'react';
import { View } from 'react-native';
import { ProgressBar } from 'react-native-paper';

export const LoadingComp = () => {
  return (
    <View
      style={{
        width: '100%',
        alignSelf: 'center',
        backgroundColor: '#ffffff',
      }}>
      <View
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          marginVertical: '100%',
          marginHorizontal: '30%',
          height: '100%',
        }}>
        <ProgressBar progress={0.5} color={'red'} />
      </View>
    </View>
  );
};
