import React,{memo, useRef} from 'react';
import {
  Button,
  Dialog,
  Paragraph,
  Portal,
  TextInput,
} from 'react-native-paper';
import { meterScreenStyles } from '../../screens/meters/styles';

const InputModal = ({
  title,
  message,
  value,
  label,
  onConfirm,
  onConfirmText,
  onDismissText,
  visible,
  onDismiss,
}: {
  title: string;
  message?: string;
  value?: string;
  label?: string;
  onConfirmText?: string;
  onDismissText?: string;
  visible: boolean;
  onDismiss: () => void;
  onConfirm: (value:string) => void;
}) => {
    const valueRef = useRef(value)
  return (
    <Portal>
      <Dialog visible={visible} onDismiss={onDismiss}>
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Content>
          
          {message&&<Paragraph>{message}</Paragraph>}
          <TextInput
            mode='outlined'
            placeholder={
                label
            }
            style={{...meterScreenStyles.input,...meterScreenStyles.textArea}}
            defaultValue={valueRef.current}
            multiline
            onChangeText={text => valueRef.current=text}
            />
        </Dialog.Content>
        <Dialog.Actions>
          {onDismissText ? (
            <Button onPress={onDismiss}>
              { onDismissText || 'Cancel'}
            </Button>
          ) : null}
          <Button onPress={()=>onConfirm(valueRef.current)}>
            {onConfirmText || 'Done'}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
  }
export default memo(InputModal)

