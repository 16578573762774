import { useEffect } from 'react';
import {
  getFirestore,
  doc,
  onSnapshot,
  query,
  limit,
  startAfter,
  getDoc,
  orderBy,
  collection,
  where,
  Timestamp
} from 'firebase/firestore';
import FirebaseApp from '../config/firebase';
import { RootState } from '../data/redux/store';
import { useSelector } from 'react-redux';
const db=getFirestore(FirebaseApp)

const useFBDocs = (form:any, callback:any) => {

  const { user } = useSelector(
    (state: RootState) => state.login
  ); 
  const userId = user?.uid;
  useEffect(() => {
    (async () => {
      if (form&&userId) {
        let allDocs: any[] = [];
        const { path:pathName, lastDoc, boolValue,meterId, posts,lim,date2,direction } = form;
        const path=pathName.replace('<uid>',userId)
        console.log('startSearch useFBDocs ', path,' meterId ',meterId,' date2 ',date2?.toString());
        let q;
        if (lastDoc) {
          const afterDoc = await getDoc(doc(db,`${path}/${lastDoc}`));
          if(meterId){

            if (boolValue) {
              q = query(collection(db,path)
                ,where(boolValue.value, '==', boolValue.key)
                ,where('meterId', '==', meterId)
                ,orderBy('createdAt', 'desc')
                ,startAfter(afterDoc)
                ,limit(15)
              )
            } else {
              q = query(collection(db,path)
                ,where('meterId', '==', meterId)
                ,orderBy('createdAt', 'desc')
                ,startAfter(afterDoc)
                ,limit(15)
              )
            }
          }else{

            if (boolValue) {
              q = query(collection(db,path)
              ,where(boolValue.value, '==', boolValue.key)
              ,orderBy('createdAt', 'desc')
                ,startAfter(afterDoc)
                ,limit(15)
              )
            } else {
              q = query(collection(db,path)
                ,orderBy('createdAt', 'desc')
                ,startAfter(afterDoc)
                ,limit(15)
              )
            }
          }
        } else {
          if (lim) {
            if (meterId) {
              
              if (boolValue) {
                console.log('path meterId ', path);
    
                q = query(collection(db,path)
                ,where('meterId', '==', meterId)
                ,where(boolValue.value, '==', boolValue.key)

                  ,orderBy('createdAt', 'desc')
                  ,limit(lim)
                )
              } else {
                console.log('path 2 ', path);
    
                q = query(
                  collection(db,path)
                  ,where('meterId', '==', meterId)
                  ,orderBy('createdAt', 'desc')
                  ,limit(lim))
              }
            } else {
              
              if (boolValue) {
                console.log('path 1 ', path);
    
                q = query(collection(db,path)
                ,where(boolValue.value, '==', boolValue.key)
                  ,orderBy('createdAt', 'desc')
                  ,limit(lim)
                )
              } else {
                console.log('path 2 ', path,' date2 ',date2);
                if(date2){
      
                  const timestamp_nxt = new Timestamp(
                    Math.round(date2 / 1000),
                    83000000
                  );
                  q = query(
                    collection(db,path),
                    orderBy('createdAt', direction||'desc'),
                    where('createdAt', '<', timestamp_nxt),
                    limit(lim))
  
                }else{
                 q = query(collection(db,path),orderBy('createdAt', direction||'desc'),limit(lim))
                }
              }
            }
          } else {
            if (boolValue) {
              console.log('path 1 ', path);
  
              q = query(collection(db,path)
              ,where(boolValue.value, '==', boolValue.key)
              ,orderBy('createdAt', 'desc')
              )
            } else {
              console.log('path 2 ', path);
              

              q = query(collection(db,path),orderBy('createdAt', 'desc'))
              
            }
            
          }
        }

        const unsubscribe = onSnapshot(q,(querySnapshot) => {
          if (querySnapshot)
            querySnapshot.docChanges().forEach((change) => {
              const data = change.doc.data();
                let payload = {
                  ...data,
                  createdAt: data.createdAt ? data.createdAt.toDate().toString() : null,
                  updatedAt: data.updatedAt
                    ? data.updatedAt.toDate().toString()
                    : null,
                    order: new Date(data.createdAt.toDate()).getTime(),

                };
                /* if(picker){
                  payload=pick(payload,picker)
                } */
              if (change.type === 'added') {
                

                //console.log('get loans inner ', change.doc.data());
                if (
                  allDocs.length === 0 ||
                  allDocs[allDocs.length - 1].order > payload.order
                ) {
                  allDocs=[...allDocs,payload];
                } else {
                  console.log('get loans unshift ',allDocs.length);                

                  allDocs=[payload,...allDocs];
                }
              } else if (change.type === 'modified') {
                
                allDocs = allDocs.map(
                  (item) => {
                    if(item._id === data._id){
                      return payload
                    }else{
                      return item
                    }
                  }
                );
                
                console.log('get modified ');                
                //allDocs[position] = payload;
              } else if (change.type === 'removed') {
                allDocs = allDocs.filter(
                  (item) => item._id !== data._id
                  );
                                
                //allDocs.splice(position, 1);
              }
            });
              callback(allDocs, posts,pathName.substring(pathName.lastIndexOf('/')+1));
        },(error)=>{
          console.log('error ',error.message);
          
        });
        return () => {
          unsubscribe()
        };
      }
    })();
  }, [form, callback,userId]);
};

export default useFBDocs;
