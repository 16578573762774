import React,{ memo } from "react";
import { View } from 'react-native';
import { DataTable, Text } from 'react-native-paper';
import CustomIconButton from "./buttons/icon-button";
import { CommonStyles } from "../utils/common-styles";
import { format } from "date-fns";

const SummaryDataListView = ({
  entries,generateExcel,firstCol,date
}: {
  entries: [];
  firstCol:string;
  date:string;
  generateExcel:(entities:any[],type:number)=>void
}) => {

  return (
    <View
      style={{
        paddingVertical: 10,
      }}>
         <View style={CommonStyles.rowContainer}>
         {entries&&entries.length>0&&<Text variant="titleMedium" style={{
        paddingHorizontal: 10,
      }}>{format(new Date(date),'MMM, yyyy')}</Text>}
            
        <CustomIconButton
          icon='share'
          pressFun={()=>generateExcel(entries,0)}
        />
      </View> 
      
      <DataTable>
        <DataTable.Header>
          <DataTable.Title >
            {firstCol}
          </DataTable.Title>
          <DataTable.Title numeric>Consumption</DataTable.Title>
        </DataTable.Header>
        {entries&&entries.map((item,idx)=>(

          <DataTable.Row key={`${item?._id}${idx}`}>
            <DataTable.Cell >
              {item?.x}
            </DataTable.Cell>
            
            <DataTable.Cell>{item?.y}</DataTable.Cell>
          </DataTable.Row>
        ))
        }
        
      </DataTable>
    </View>
  );
};

export default memo(SummaryDataListView);

