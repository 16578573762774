import { makeId } from '../utils/randomId';

export const countryList = [
  { _id: makeId(), value: 'Zambia' },
];

export const LOCATION = [
  {
    province: 'Copperbelt',
    district: [
      {
        _id: makeId(),
        value: 'Chililabombwe',
      },
      { _id: makeId(), value: 'Chingola' },
      { _id: makeId(), value: 'Kalulushi' },
      { _id: makeId(), value: 'Kitwe' },
      { _id: makeId(), value: 'Luanshya' },
      { _id: makeId(), value: 'Lufwanyama' },
      { _id: makeId(), value: 'Masaiti' },
      { _id: makeId(), value: 'Mpongwe' },
      { _id: makeId(), value: 'Mufulira' },
      { _id: makeId(), value: 'Ndola' },
    ],
  },
  {
    province: 'Eastern',
    district: [
      { _id: makeId(), value: 'Chadiza' },
      { _id: makeId(), value: 'Chama' },
      { _id: makeId(), value: 'Chasefu' },
      { _id: makeId(), value: 'Chipangali' },
      { _id: makeId(), value: 'Chipata' },
      { _id: makeId(), value: 'Kasenengwa' },
      { _id: makeId(), value: 'Katete' },
      { _id: makeId(), value: 'Lumezi' },
      { _id: makeId(), value: 'Lundazi' },
      { _id: makeId(), value: 'Lusangazi' },
      { _id: makeId(), value: 'Mambwe' },
      { _id: makeId(), value: 'Nyimba' },
      { _id: makeId(), value: 'Petauke' },
      { _id: makeId(), value: 'Sinda' },
      { _id: makeId(), value: 'Vubwi' },
    ],
  },

  {
    province: 'Luapula',
    district: [
      { _id: makeId(), value: 'Chembe' },
      { _id: makeId(), value: 'Chiengi' },
      {
        _id: makeId(),
        value: 'Chifunabuli',
      },
      { _id: makeId(), value: 'Chipili' },
      { _id: makeId(), value: 'Kawambwa' },
      { _id: makeId(), value: 'Lunga' },
      { _id: makeId(), value: 'Mansa' },
      { _id: makeId(), value: 'Milenge' },
      {
        _id: makeId(),
        value: 'Mwansabombwe',
      },
      { _id: makeId(), value: 'Mwense' },
      { _id: makeId(), value: 'Nchelenge' },
      { _id: makeId(), value: 'Samfya' },
    ],
  },

  {
    province: 'Lusaka',
    district: [
      { _id: makeId(), value: 'Chilanga' },
      { _id: makeId(), value: 'Chongwe' },
      { _id: makeId(), value: 'Kafue' },
      { _id: makeId(), value: 'Luangwa' },
      { _id: makeId(), value: 'Lusaka' },
      { _id: makeId(), value: 'Rufunsa' },
    ],
  },
  {
    province: 'Muchinga',
    district: [
      { _id: makeId(), value: 'Chinsali' },
      { _id: makeId(), value: 'Isoka' },
      { _id: makeId(), value: 'Kanchibiya' },
      {
        _id: makeId(),
        value: 'Lavushimanda',
      },
      { _id: makeId(), value: 'Mafinga' },
      { _id: makeId(), value: 'Mpika' },
      { _id: makeId(), value: 'Nakonde' },
      {
        _id: makeId(),
        value: "Shiwang'andu",
      },
    ],
  },
  {
    province: 'Northern',
    district: [
      { _id: makeId(), value: 'Chilubi' },
      { _id: makeId(), value: 'Kaputa' },
      { _id: makeId(), value: 'Kasama' },
      { _id: makeId(), value: 'Lunte' },
      { _id: makeId(), value: 'Lupososhi' },
      { _id: makeId(), value: 'Luwingu' },
      { _id: makeId(), value: 'Mbala' },
      { _id: makeId(), value: 'Mporokoso' },
      { _id: makeId(), value: 'Mpulungu' },
      { _id: makeId(), value: 'Mungwi' },
      { _id: makeId(), value: 'Nsama' },
      { _id: makeId(), value: 'Senga' },
    ],
  },
  {
    province: 'North Western',
    district: [
      { _id: makeId(), value: 'Chavuma' },
      { _id: makeId(), value: 'Ikelenge' },
      { _id: makeId(), value: 'Kabompo' },
      { _id: makeId(), value: 'Kasempa' },
      { _id: makeId(), value: 'Kalumbila' },
      { _id: makeId(), value: 'Manyinga' },
      { _id: makeId(), value: 'Mufumbwe' },
      { _id: makeId(), value: 'Mushindamo' },
      { _id: makeId(), value: 'Mwinilunga' },
      { _id: makeId(), value: 'Solwezi' },
      { _id: makeId(), value: 'Zambezi' },
    ],
  },
  {
    province: 'Southern',
    district: [
      { _id: makeId(), value: 'Chirundu' },
      { _id: makeId(), value: 'Choma' },
      { _id: makeId(), value: 'Gwembe' },
      {
        _id: makeId(),
        value: 'Itezhi-Tezhi',
      },
      { _id: makeId(), value: 'Kalomo' },
      { _id: makeId(), value: 'Kazungula' },
      {
        _id: makeId(),
        value: 'Livingstone',
      },
      { _id: makeId(), value: 'Mazabuka' },
      { _id: makeId(), value: 'Monze' },
      { _id: makeId(), value: 'Namwala' },
      { _id: makeId(), value: 'Pemba' },
      { _id: makeId(), value: 'Siavonga' },
      { _id: makeId(), value: 'Sinazongwe' },
      { _id: makeId(), value: 'Zimba' },
      { _id: makeId(), value: 'Chikankata' },
    ],
  },
  {
    province: 'Western',
    district: [
      { _id: makeId(), value: 'Kalabo' },
      { _id: makeId(), value: 'Kaoma' },
      { _id: makeId(), value: 'Limulunga' },
      { _id: makeId(), value: 'Luampa' },
      { _id: makeId(), value: 'Lukulu' },
      { _id: makeId(), value: 'Mitete' },
      { _id: makeId(), value: 'Mongu' },
      { _id: makeId(), value: 'Mulobezi' },
      { _id: makeId(), value: 'Mwandi' },
      { _id: makeId(), value: 'Nalolo' },
      { _id: makeId(), value: 'Nkeyema' },
      { _id: makeId(), value: 'Senanga' },
      { _id: makeId(), value: 'Sesheke' },
      { _id: makeId(), value: "Shang'ombo" },
      { _id: makeId(), value: 'Sikongo' },
      { _id: makeId(), value: 'Sioma' },
    ],
  },
  {
    province: 'Central',
    district: [
      { _id: makeId(), value: 'Chibombo' },
      { _id: makeId(), value: 'Chisamba' },
      { _id: makeId(), value: 'Chitambo' },
      { _id: makeId(), value: 'Kabwe' },
      { _id: makeId(), value: 'Kapiri Mposhi' },
      { _id: makeId(), value: 'Luano' },
      { _id: makeId(), value: 'Mkushi' },
      { _id: makeId(), value: 'Mumbwa' },
      { _id: makeId(), value: 'Ngabwe' },
      { _id: makeId(), value: 'Serenje' },
      { _id: makeId(), value: 'Shibuyunji' },
    ],
  },
];
const otherCategories={ _id: makeId(), value: 'Others' }
export const CATEGORY = [
  {
    category: 'Residential',
    appliances: [
      { _id: makeId(), value: 'Stove' },
      { _id: makeId(), value: 'Home Air Conditioning' },
      { _id: makeId(), value: 'Refrigerator' },
      {
        _id: makeId(),
        value:
          'Home Heating System (water heater, geyser, Microwave etc)',
      },
      { _id: makeId(), value: 'Washer and Dryer' },
      { _id: makeId(), value: 'Televisions' },
      {
        _id: makeId(),
        value:
          'Food processor (blender, toaster, coffe maker etc)',
      },
      otherCategories
    ],
  },
  {
    category: 'Commercial',
    appliances: [
      { _id: makeId(), value: 'Food processor' },
      { _id: makeId(), value: 'Cooking equipment' },
      { _id: makeId(), value: 'Heating equipment' },
      {
        _id: makeId(),
        value: 'Computers',
      },
      { _id: makeId(), value: 'Printers' },
      otherCategories
    ],
  },
  {
    category: 'Agriculture',
    appliances: [
      { _id: makeId(), value: 'Water Pump' },
      { _id: makeId(), value: 'Food processor' },
      { _id: makeId(), value: 'Hammer mill' },
      { _id: makeId(), value: 'Pressure booster' },
      { _id: makeId(), value: 'Feed mixer' },
      otherCategories
    ],
  },

  {
    category: 'Manufacturing',
    appliances: [
      { _id: makeId(), value: 'Grinder' },
      { _id: makeId(), value: 'Cutters' },
      { _id: makeId(), value: 'Press' },
      {
        _id: makeId(),
        value: 'Belt Conveyor ',
      },
      { _id: makeId(), value: 'Milling machine' },
      { _id: makeId(), value: 'Meat processing machine' },
      {
        _id: makeId(),
        value: 'Parts Straightener ',
      },
      otherCategories
    ],
  },

  {
    category: 'Mining',
    appliances: [
      { _id: makeId(), value: 'Mining Drills' },
      { _id: makeId(), value: 'Electrical Blasting Tools' },
      {
        _id: makeId(),
        value: 'Electrical  Crushing Equipment',
      },
      {
        _id: makeId(),
        value: 'Crushing Equipment',
      },
      otherCategories
    ],
  },
];
