import React from 'react';
import { StyleSheet } from 'react-native';
import { FAB } from 'react-native-paper';

export const CustomFAB = ({bg,icon,color,onPress}: {bg:string;icon:string;color:string;onPress:()=>void}) => {
  return (
    <FAB
      style={{...styles.fab,backgroundColor: bg}}
      small
      {...{icon,color,onPress}}
    />
  );
};

const styles = StyleSheet.create({
  fab: {
    position: 'absolute',
    margin: 16,
    borderRadius: 100,
    right: 0,
    bottom: 10,
    color: 'white',
    backgroundColor: 'red',
  },
});
