import React, { memo } from 'react';
import { Image, View, ViewProps } from 'react-native';
import { Text } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { colors } from '../theme/colors';

const BadgeComp = ({
	title,
	image,
	description,
	isEarned,
	props,
}: {
	title: string;
	image: string;
	description: string;
	isEarned: boolean;
	props?: ViewProps;
}) => (
	<View
		style={{
			alignSelf: 'center',
			marginTop: 10,
		}}
		{...props}
	>
		<Image
			style={{
				width: 300,
				height: 300,
				resizeMode: 'contain',
				opacity: !isEarned ? 0.3 : undefined,
			}}
			source={{ uri: image }}
		/>

		{!isEarned && (
			<MaterialCommunityIcons
				style={{
					position: 'absolute',
					alignSelf: 'flex-end',
					marginTop: 20,
				}}
				name='lock'
				size={24}
				color={colors.secondary}
			/>
		)}
		<View
			style={{
				width: '36%',
				alignSelf: 'center',
				marginTop: 10,
				opacity: !isEarned ? 0.3 : undefined,
			}}
		>
			<Text
				numberOfLines={2}
				style={{
					fontSize: 17,
					fontWeight: '700',
					flexWrap: 'wrap',
					textAlign: 'center',
				}}
			>
				{title || description}
			</Text>
		</View>
	</View>
);

export default memo(BadgeComp);
