import { Platform, StyleSheet,Dimensions } from 'react-native';

 const height = Dimensions 

export const meterScreenStyles = StyleSheet.create({
  mainContainer: {
    height: "100%",
    marginHorizontal: "5%",
    marginTop: Platform.OS === "web" ? "10%" : undefined,
    display: "flex",
    flexDirection: "column",
  },
  textArea: {
    height: 100,
  },
  dropdown: {
    borderColor: "#000",
    borderWidth: 1,
    paddingVertical: 5,
    borderRadius: 4,
    paddingHorizontal: 10,
  },
  dropdownContainer: {
    borderColor: "#000",
    borderWidth: 1,
    borderRadius: 4,
  },
  dropdownItemContainer: {
    borderTopWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 4,
  },
  keyboardAvoidingContainer: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    height: "200%",
  },
  keyboardAvoidingContainerDesktop: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    height: "200%",
  },
  inputContainerDesktop: {
    marginLeft: "30%",
  },
  input: {
    color: "#000",
    backgroundColor: "white",
    marginBottom: 4,
  },
  title: {
    paddingTop: 10,
    fontSize: 35,
    fontWeight: "bold",
    marginBottom: "20%",
    alignSelf: "center",
    color: "black",
  },
  titleDesktop: {
    paddingTop: 10,
    fontSize: 35,
    fontWeight: "bold",
    marginBottom: 20,

    color: "black",
  },
  buttonContainer: {
    // flex: 1,
    marginBottom: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
});
