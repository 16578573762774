
import {
  getFirestore,
  setDoc,
  doc,
  deleteDoc,
  query,
  collection,
  orderBy,
  where,
  limit,
  Timestamp,
  getDocs,
} from 'firebase/firestore';
import FirebaseApp from '../../config/firebase';
import { ObjectID } from 'bson';

import { pick,isEmpty } from "lodash";
import { differenceInHours } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../data/redux/store';

export const useMeters = () => {
  const { user } = useSelector(
    (state: RootState) => state.login
  ); 
  const userId = user?.uid;

  

  const firestore = getFirestore(FirebaseApp);
  const deleteMeter = async (docId:string) => {
    const docRef = doc(firestore, `users/${userId}/meters`, docId);

    await deleteDoc(docRef)
      .then(() => {
        console.log(':This is for ==> deleted:');
      })
      .catch((e) => {
        console.log('deleteDocError', e);
      });
  };

  const addModData = async (data:any,id:string,path:string,picker?:any,prev?:any,callback?:(msg:any)=>void) => {

    const _id =!isEmpty(id)?id: new ObjectID().toString();

    const newRecord = picker?{
      _id,
      userId,
      ...pick(data,picker),
    }:data;
    const pathName=path.replace('<uid>',`${userId}`)
    let msg,prevDate,prevReading;
    if(path.includes('meter-readings')){
      const{meterData=0,lastTopUp=0,createdAt,meterId}=data
      const date=createdAt||new Date()
          const timestamp_nxt = new Timestamp(
            Math.round(date.getTime()/ 1000),
            83000000
          );
      if(prev&&(prev.meterId===meterId)){
        const {meterData,createdAt}=prev
        prevDate=new Date(createdAt)
        prevReading=meterData
      }else{
        const q = query(
          collection(firestore,pathName),
          orderBy('createdAt', 'desc'),
          where('createdAt', '<', timestamp_nxt),
          where('meterId', '==', meterId),
          limit(1))
          const docs=await getDocs(q)
          if(!docs.empty){
           
            const {meterData,createdAt}=docs.docs[0].data()
            prevDate=createdAt.toDate()
            prevReading=meterData
          }
        }
        const dateDiff=differenceInHours(date,prevDate)
        console.log('dateDiff ',dateDiff);
        
        if(dateDiff<1){
          msg='Should atleast be a interval of 1 hour between readings'

        }
        const diff=prevReading-(meterData-lastTopUp);
        if(diff<0){
          msg='Your inputs result in negative consumption, please provide correct inputs'
        }
    }
    if(!msg){

      await setDoc(
        doc(firestore, pathName, _id),
        newRecord,
        {merge:true}
      );
    }
    if(callback){

      callback(msg)
    }
  };

 

  return {
    addModData,
    deleteMeter,
  };
};
