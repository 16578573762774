import React from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-paper'

const ChartKey = ({title,color}:{title:string;color:string}) => {
  return (
    <View
        style={{
          marginVertical: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems:'center'
        }}>

<View
          style={{
            borderRadius: 100,
            height: 10,
            width: 10,
            backgroundColor: color,
          }}
        />
      <Text style={{
          marginHorizontal: 2,
          color:'grey'
        }} variant="labelSmall">
              {title}
            </Text>
        </View>
  )
}

export default ChartKey