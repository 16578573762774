import { StyleSheet } from 'react-native';

export const CommonStyles = StyleSheet.create({
    rowContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        width:'100%'
      },
})