import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	KeyboardAvoidingView,
	Platform,
	ScrollView,
	StyleSheet,
	View,
} from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import { Text, TextInput } from 'react-native-paper';
import { Button } from '../../components/buttons/custom-button';
import { LayoutWrapper } from '../../components/layout-wrapper';
import { LoadingComp } from '../../components/loading-comp';
import { ScreenTitleWrapper } from '../../components/screen-title-wrapper';
import { notesList } from '../../constants/meter-data';
import { useMeters } from '../../hooks/use-meters';
import { AddReadingForm } from './addReadingForm';
import ConfirmationModal from '../../components/dialog/confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../data/redux/store';
import { setCurrentMeter } from '../../data/redux/slices/meter';
import { addMeterDefaultValues } from '../meters/constants';
import Prompts from '../../components/prompts';

const EditReadings = ({ navigation, route }: any) => {
	const { current: readingData = {}, prev } = route.params;
	const isEdit = route.name === 'EditReadings';
	const [addingMeter, setAdding] = useState<boolean>(false);

	const [visible, setVisible] = useState<boolean>(false);

	const [modalMsg, setmodalMsg] = useState<any>();
	const mounted = useRef<boolean>(false);
	const { meters, currentMeter } = useSelector(
		(state: RootState) => state.meter,
	);
	const { addModData } = useMeters();
	// const readingData = item.route.params;

	const saveCallback = (msg: any) => {
		if (msg) {
			setAdding(false);
			setmodalMsg({
				title: 'Readings Validation',
				warnMassage: msg,
				onConfirmText: 'Okay',
			});
			setVisible(true);
		} else {
			navigation.goBack();
		}
	};
	const dispatch = useDispatch();

	/* const metersList =useMemo(()=>meters.reduce((cal:any,item: any) => {
    return [...cal,{
      _id: item?._id,
      value: item.name,
    }];
  },[]),[meters]) */
	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false;
		};
	}, []);
	const {
		isArchived = false,
		meterId = '',
		_id = '',
		createdAt,
		lastTopUp = 0,
		description = '',
		meterData = 0,
	} = readingData;
	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: '',
			meterId: currentMeter?.name,

			lastTopUp: `${lastTopUp}`,

			description,
			meterData: `${meterData}`,
		},
	});

	return (
		<View>
			<LayoutWrapper>
				<ScreenTitleWrapper title={isEdit ? 'Edit Reading' : 'Add Reading'}>
					{meters && meters.length > 0 ? (
						<ScrollView style={styles.mainContainer}>
							<KeyboardAvoidingView
								behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
								style={styles.keyboardAvoidingContainer}
							>
								<Controller
									control={control}
									name='meterId'
									rules={{
										required: true,
										minLength: 4,
									}}
									render={({ field: { onChange, value } }) => (
										<Dropdown
											placeholder='Select Meter'
											style={styles.dropdown}
											disable={isEdit}
											containerStyle={styles.dropdownContainer}
											itemContainerStyle={styles.dropdownItemContainer}
											onChange={(item) => {
												dispatch(setCurrentMeter(item));
												onChange(item.name);
											}}
											data={meters}
											valueField='name'
											labelField='name'
											value={value}
										/>
									)}
								/>
								{errors.meterId?.type == 'required' ? (
									<Text style={styles.errorText}>Meter is required.</Text>
								) : (
									errors.meterId?.type == 'minLength' && (
										<Text style={styles.errorText}>
											Meter Name should be a min of 4 characters long.
										</Text>
									)
								)}
								<View>
									<View
										style={{
											marginBottom: 10,
											marginTop: 5,
										}}
									>
										<Controller
											control={control}
											rules={{
												required: true,
												minLength: 1,
												min: 1,
											}}
											render={({ field: { onChange, value } }) => (
												<TextInput
													mode='outlined'
													label={'Current Reading (kWh)'}
													placeholder='Current Reading (kWh)'
													style={styles.input}
													value={value}
													keyboardType='numeric'
													onChangeText={onChange}
												/>
											)}
											name='meterData'
										/>
										{errors.meterData?.type == 'required' ? (
											<Text style={styles.errorText}>
												Meter Data is required.
											</Text>
										) : errors.meterData?.type == 'min' ? (
											<Text style={styles.errorText}>
												Meter Data should be a min of 1 unit.
											</Text>
										) : (
											errors.meterData?.type == 'minLength' && (
												<Text style={styles.errorText}>
													Meter Data should be a min length of 1.
												</Text>
											)
										)}
									</View>
									<AddReadingForm
										// value={readingData.lastTopUp}
										styles={styles}
										errors={errors}
										control={control}
										watch={watch}
									/>

									<View
										style={{
											marginBottom: 20,
										}}
									>
										<Controller
											control={control}
											rules={{
												minLength: 4,
											}}
											render={({ field: { onChange, value } }) => (
												<Dropdown
													placeholder='Select Notes'
													style={styles.dropdown}
													containerStyle={styles.dropdownContainer}
													itemContainerStyle={styles.dropdownItemContainer}
													onChange={(item) => {
														onChange(item?.value);
													}}
													data={notesList}
													valueField='value'
													labelField='value'
													value={value}
												/>
											)}
											name='description'
										/>
										{errors.description?.type == 'required' ? (
											<Text style={styles.errorText}>
												Meter description is required.
											</Text>
										) : (
											errors.description?.type == 'minLength' && (
												<Text style={styles.errorText}>
													Meter Nam should be a min of 4 characters long.
												</Text>
											)
										)}
									</View>
								</View>
								<View
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										//marginBottom:'40%'
									}}
								>
									<Button
										mode='outlined'
										onPress={() => {
											navigation.navigate('All Readings');
										}}
									>
										Cancel
									</Button>
									<Button
										onPress={handleSubmit(async (data) => {
											console.log('data', data);
											setAdding(true);
											const date = new Date();
											const { meterData = '0', lastTopUp = '0' } = data;
											const purseData = {
												...data,
												//id: readingData._id,
												meterId: isEdit ? meterId : currentMeter?._id,
												updatedAt: date,
												isArchived,
												createdAt: isEdit ? new Date(createdAt) : date,
												meterData: Number(meterData),
												lastTopUp: Number(lastTopUp),
											};
											console.log('====================================');
											console.log('meterID', purseData);
											console.log('====================================');

											await addModData(
												purseData as unknown,
												_id,
												`users/<uid>/meter-readings`,
												[
													'meterId',
													'meterData',
													'isArchived',
													'lastTopUp',
													'description',
													'updatedAt',
													'createdAt',
												],
												prev,
												saveCallback,
											);
										})}
										loading={addingMeter}
										disabled={addingMeter}
										textColor='#fff'
									>
										Save
									</Button>
								</View>
							</KeyboardAvoidingView>
						</ScrollView>
					) : (
						<Prompts
							title='Get Started!'
							step1='Step 1: Create meter on the meter tab.'
							step2='Step 2: Add meter readings here.'
							height='100%'
							nav1={{
								name: 'Meters',
								screen: 'AddMeter',
								params: addMeterDefaultValues,
							}}
						/>
					)}
				</ScreenTitleWrapper>
			</LayoutWrapper>
			<ConfirmationModal
				{...{ ...modalMsg, visible }}
				onConfirm={() => setVisible(false)}
			/>
		</View>
	);
};
const styles = StyleSheet.create({
	mainContainer: {
		height: '100%',
		marginHorizontal: '5%',
		marginTop: Platform.OS === 'web' ? '10%' : undefined,
		display: 'flex',
		flexDirection: 'column',
	},
	dropdown: {
		backgroundColor: 'white',
		borderColor: '#000',
		borderWidth: 1,
		paddingVertical: 5,
		borderRadius: 4,
		paddingHorizontal: 10,
	},
	dropdownContainer: {
		borderColor: '#000',
		borderWidth: 1,
		borderRadius: 4,
	},
	dropdownItemContainer: {
		borderTopWidth: 1,
		borderColor: 'lightgrey',
		borderRadius: 4,
	},
	keyboardAvoidingContainer: {
		width: '75%',
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'center',
	},
	keyboardAvoidingContainerDesktop: {
		width: '40%',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center',
		alignItems: 'center',
	},
	inputContainerDesktop: {
		marginLeft: '30%',
	},
	input: {
		color: '#000',
		backgroundColor: 'white',
		marginBottom: 4,
	},

	title: {
		paddingTop: 10,
		fontSize: 35,
		fontWeight: 'bold',
		marginBottom: '20%',
		alignSelf: 'center',
		color: 'black',
	},
	titleDesktop: {
		paddingTop: 10,
		fontSize: 35,
		fontWeight: 'bold',
		marginBottom: 20,

		color: 'black',
	},
	errorText: {
		color: 'red',
	},
});

export default EditReadings;
