import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import DataView from '../screens/DataView';
import Home from '../screens/home';

const Stack = createNativeStackNavigator();
export const HomeStack = () => {
  return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          contentStyle: {
            backgroundColor: 'white',
          },
        }}>
        <Stack.Screen
          options={{}}
          name='HomeView'
          component={Home}
        />
        <Stack.Screen
          options={{}}
          name='DataView'
          component={DataView}
        />
      </Stack.Navigator>
  );
};
