export const meterFields = {
  NAME: {
    name: 'name',
    label: 'Name',
    dbFieldName: 'name',
  },
  METER_NUMBER: {
    name: 'meterNumber',
    label: 'Enter Meter Number',
    dbFieldName: 'meterNumber',
  },
  PREFERRED_AVERAGE: {
    name: 'userAverage',
    label: 'Preferred (kWh) per day',
    dbFieldName: 'userAverage',
  },
  CATEGORY: {
    name: 'categoryId',
    label: 'Select Category',
    dbFieldName: 'categoryId',
  },
  APPLIANCES: {
    name: 'applianceId',
    label: 'Select Appliance ',
    dbFieldName: 'applianceId',
  },
  OTHER_APPLIANCES: {
    name: 'otherId',
    label: 'Other appliances, separated by comas.',
    dbFieldName: 'otherId',
  },
  LOCATION: {
    name: 'location',
    label: 'Select Location',
    dbFieldName: 'location',
  },
  COUNTRY: {
    name: 'countryId',
    label: 'Select Country',
    dbFieldName: 'countryId',
  },
  TOWN: {
    name: 'townId',
    label: 'Select Town',
    dbFieldName: 'townId',
  },
};

export const addMeterDefaultValues = {
  [meterFields.NAME.name]: '',
  [meterFields.METER_NUMBER.name]: '',
  [meterFields.PREFERRED_AVERAGE.name]: '',
  [meterFields.CATEGORY.name]: '',
  [meterFields.APPLIANCES.name]: [],
  [meterFields.OTHER_APPLIANCES.name]: '',
  [meterFields.COUNTRY.name]: '',
  [meterFields.LOCATION.name]: '',
};
