import { makeId } from './../utils/randomId';
export const notesList = [
  {
    _id: makeId(),
    value: 'Power Cut',
  },
  {
    _id: makeId(),
    value: 'Increased Production',
  },
   {
    _id: makeId(),
    value: 'Holiday',
  },
];
