import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { KeyboardAvoidingView, Platform, ScrollView, View } from "react-native";
import { TextInput } from "react-native-paper";

import { Dropdown } from "react-native-element-dropdown";
import { Button } from "../../components/buttons/custom-button";
import { CustomFAB } from "../../components/buttons/custom-fab";
import ConfirmationModal from "../../components/dialog/confirmation";
import InputModal from "../../components/dialog/text-area-dialog";
import { ScreenTitleWrapper } from "../../components/screen-title-wrapper";
import { CATEGORY, countryList, LOCATION } from "../../constants/meters";
import { useMeters } from "../../hooks/use-meters";
import { MeterType } from "../../hooks/use-meters/types";
import { useDesktopMediaQuery } from "../../hooks/useIsDesktop";
import { meterFields } from "./constants";
import { meterScreenStyles } from "./styles";
import { ErrorBox } from "../../components/errorBox";
import { meterRules } from "../../constants/meter/rules";
import { includes, remove, isEmpty } from "lodash";

import { Checkbox } from "../../components/checkbox";
import { sortByDesc } from "../../utils/sortByDesc";
import { splitStr } from "../../utils/splitStr";

const EditMeter = ({ route, navigation }: any) => {
  const [updatingMeter, setUpdating] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showInputModal, setShowInputModal] = useState(false);
  const [saveError, setSaveError] = useState(true);
  const isEdit = route.name === "EditMeter";
  console.log("isEdit ", isEdit);

  const { deleteMeter, addModData } = useMeters();

  const {
    _id,
    name,
    location = "",
    townId,
    countryId,
    meterNumber,
    userAverage,
    categoryId,
    createdAt,
    isArchived = false,
    applianceId,
    otherId = [""],
  } = route.params;
  console.log("userAverage ", userAverage);
  const ArrToString = useMemo(() => {
    try {
      return otherId.reduce((cal, val, index) => {
        const append = otherId.length - 1 === index ? "" : ",";
        return `${cal} ${val} ${append}`;
      }, "");
    } catch (error) {
      return "";
    }
  }, [otherId]);
  const {
    control,
    handleSubmit,
    watch,
    setValue,

    formState: { errors },
  } = useForm({
    defaultValues: {
      id: _id,
      name: name || "",
      meterNumber: meterNumber || 0,
      categoryId: categoryId || "",
      countryId: countryId || "",
      location: location || "",
      townId: townId || "",
      applianceId: applianceId || "",
      otherId: isEdit ? ArrToString : "",
      userAverage: userAverage ? `${userAverage}` : "",
    },
  });

  const { isDesktop } = useDesktopMediaQuery();

  const [locator, setlocation] = useState(location);

  const [category, setCategory] = useState(categoryId);
  const [selectedAppliances, setSelectedAppliances] = useState(applianceId);

  const towns = useMemo(() => {
    if (isEmpty(locator)) {
      return [];
    } else {
      return LOCATION.find((item) => {
        return item.province === locator;
      })?.district;
    }
  }, [locator]);
  const provinces = useMemo(
    () =>
      LOCATION.map((item) => {
        return { label: item.province, value: item.province };
      }),
    []
  );
  console.log("locator ", locator, " provinces ", provinces);
  const appliances = useMemo(
    () =>
      CATEGORY.filter((item) => {
        return item.category === category;
      }),
    [category]
  );
  const appl = appliances[0]?.appliances ?? [];
  const submitToFB = async (data: any) => {
    setUpdating(true);
    const { userAverage = "0", otherId } = data;
    const updatedAt = new Date();
    const purseData = {
      ...data,
      otherId: otherId.includes(",") ? splitStr(otherId) : [otherId.trim()],
      userAverage: Number(userAverage),
      updatedAt,
      createdAt: isEdit ? new Date(createdAt) : updatedAt,
      isArchived,
    };
    const id = isEdit ? _id : "";
    addModData(purseData as unknown as MeterType, id, `users/<uid>/meters`, [
      "categoryId",
      "location",
      "countryId",
      "meterNumber",
      "userAverage",
      "name",
      "townId",
      "updatedAt",
      "createdAt",
      "applianceId",
      "otherId",
      "isArchived",
    ])
      .then(() => {
        setUpdating(false);
        navigation.goBack();
      })
      .catch((e) => {
        console.log("====================================");
        console.log("Failed to send error", e);
        console.log("====================================");
        setUpdating(false);
        setSaveError(true);
      });
  };
  return (
    <>
      <ScreenTitleWrapper title={isEdit ? "Edit Meter" : "Add Meter"}>
        <ScrollView style={meterScreenStyles.mainContainer}>
          <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : "height"}
            style={
              isDesktop
                ? meterScreenStyles.keyboardAvoidingContainerDesktop
                : meterScreenStyles.keyboardAvoidingContainer
            }
          >
            <View>
              <View
                style={{
                  marginBottom: 10,
                }}
              >
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    minLength: 4,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      mode="outlined"
                      label={"Meter Name"}
                      placeholder="Enter Meter Name"
                      style={meterScreenStyles.input}
                      value={value}
                      onChangeText={onChange}
                    />
                  )}
                  name="name"
                />
                <ErrorBox field="Meter Name" errors={errors.name} length={4} />
              </View>

              <View
                style={{
                  marginBottom: 10,
                }}
              >
                <Controller
                  control={control}
                  name="meterNumber"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      mode="outlined"
                      label={"Meter Number"}
                      placeholder="Enter Meter Number"
                      style={meterScreenStyles.input}
                      value={value}
                      keyboardType="numeric"
                      onChangeText={onChange}
                    />
                  )}
                />
                <ErrorBox
                  field="Meter number"
                  length={8}
                  errors={errors.meterNumber}
                />
              </View>
              <View
                style={{
                  marginBottom: 15,
                }}
              >
                <Controller
                  control={control}
                  name={meterFields.PREFERRED_AVERAGE.name}
                  rules={meterRules.average}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      mode="outlined"
                      label={meterFields.PREFERRED_AVERAGE.label}
                      placeholder={meterFields.PREFERRED_AVERAGE.label}
                      style={meterScreenStyles.input}
                      value={value}
                      keyboardType="numeric"
                      onChangeText={onChange}
                    />
                  )}
                />
                <ErrorBox
                  field="Average consumption"
                  length={8}
                  errors={errors.userAverage}
                />
              </View>
              <View
                style={{
                  marginBottom: 20,
                }}
              >
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="categoryId"
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      placeholder={meterFields.CATEGORY.label}
                      style={meterScreenStyles.dropdown}
                      containerStyle={meterScreenStyles.dropdownContainer}
                      itemContainerStyle={
                        meterScreenStyles.dropdownItemContainer
                      }
                      onChange={(item) => {
                        setCategory(item.category);
                        onChange(item.category);
                        item.category !== category && setSelectedAppliances([]);
                      }}
                      data={sortByDesc(CATEGORY, "value")}
                      valueField="category"
                      labelField="category"
                      value={value}
                    />
                  )}
                />
                <ErrorBox field="Category" errors={errors.categoryId} />
              </View>
              <View
                style={{
                  marginBottom: 20,
                }}
              >
                <Controller
                  control={control}
                  name={meterFields.APPLIANCES.name}
                  //rules={meterRules.appliances}
                  render={({ field: { onChange } }) => (
                    <>
                      {appl?.map((item) => (
                        <Checkbox
                          key={item._id}
                          text={item.value}
                          status={
                            includes(selectedAppliances, item.value)
                              ? "checked"
                              : "unchecked"
                          }
                          onPress={() => {
                            if (!includes(selectedAppliances, item.value)) {
                              setSelectedAppliances((oldArray) => {
                                let newArray = [...oldArray, item.value];
                                onChange(newArray);
                                return newArray;
                              });
                            } else {
                              if (selectedAppliances.length !== 0) {
                                let newArray = remove(
                                  selectedAppliances,
                                  function (aItem) {
                                    return aItem !== item.value;
                                  }
                                );
                                setSelectedAppliances(newArray);
                                onChange(newArray);
                              }
                              return;
                            }
                          }}
                        />
                      ))}
                    </>
                  )}
                />
              </View>
              {selectedAppliances.includes("Others") && (
                <View
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <Controller
                    control={control}
                    name={meterFields.OTHER_APPLIANCES.name}
                    render={({ field: { value } }) => (
                      <TextInput
                        mode="outlined"
                        label={meterFields.OTHER_APPLIANCES.label}
                        placeholder={meterFields.OTHER_APPLIANCES.label}
                        onPressIn={() => setShowInputModal(true)}
                        style={meterScreenStyles.input}
                        value={value}
                        disabled
                        right={
                          <TextInput.Icon
                            onPress={() => {
                              setShowInputModal(true);
                            }}
                            // @ts-ignore
                            icon="pencil-outline"
                          />
                        }

                        //onChangeText={onChange}
                      />
                    )}
                  />
                </View>
              )}
              <View
                style={{
                  marginBottom: 20,
                }}
              >
                <Controller
                  control={control}
                  name="countryId"
                  render={({ field: { onChange, value: renderValue } }) => (
                    <Dropdown
                      placeholder="Select Country"
                      style={meterScreenStyles.dropdown}
                      containerStyle={meterScreenStyles.dropdownContainer}
                      itemContainerStyle={
                        meterScreenStyles.dropdownItemContainer
                      }
                      onChange={(item) => {
                        onChange(item.value);
                      }}
                      data={sortByDesc(countryList, "value")}
                      valueField="value"
                      labelField="value"
                      value={renderValue}
                    />
                  )}
                />
                <ErrorBox field="Country" errors={errors.countryId} />
              </View>
              {provinces && (
                <View
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Controller
                    control={control}
                    name="location"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Dropdown
                        placeholder="Select Province"
                        style={meterScreenStyles.dropdown}
                        containerStyle={meterScreenStyles.dropdownContainer}
                        itemContainerStyle={
                          meterScreenStyles.dropdownItemContainer
                        }
                        onChange={(item) => {
                          console.log("item ", item);

                          setlocation(item.value);
                          onChange(item.value);
                        }}
                        data={provinces}
                        valueField="value"
                        labelField="label"
                        value={value}
                      />
                    )}
                  />
                  <ErrorBox field="Location" errors={errors.location} />
                </View>
              )}
              {towns && (
                <View
                  style={{
                    marginBottom: 40,
                  }}
                >
                  <Controller
                    control={control}
                    name={meterFields.TOWN.name}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Dropdown
                        placeholder={meterFields.TOWN.label}
                        style={meterScreenStyles.dropdown}
                        containerStyle={meterScreenStyles.dropdownContainer}
                        itemContainerStyle={
                          meterScreenStyles.dropdownItemContainer
                        }
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        data={sortByDesc(towns, "district")}
                        valueField="value"
                        labelField="value"
                        value={value}
                        // activeColor={colors.secondary}
                        disable={!locator}
                      />
                    )}
                  />
                  <ErrorBox
                    field="Town"
                    // TODO: Fix type Error
                    errors={errors.townId}
                  />
                </View>
              )}
            </View>
            <View style={meterScreenStyles.buttonContainer}>
              <Button
                mode="outlined"
                onPress={() => {
                  navigation.goBack();
                }}
              >
                Cancel
              </Button>
              <Button
                mode="contained"
                onPress={handleSubmit((data) => submitToFB(data))}
                loading={updatingMeter}
                disabled={updatingMeter}
                textColor="#fff"
              >
                Save
              </Button>
            </View>
          </KeyboardAvoidingView>
        </ScrollView>
      </ScreenTitleWrapper>
      <InputModal
        title={"Other Appliances"}
        message={meterFields.OTHER_APPLIANCES.label}
        value={watch("otherId")}
        label={"Other appliances"}
        visible={showInputModal}
        onConfirm={(txt) => {
          setValue("otherId", txt);
          setShowInputModal(false);
        }}
        onConfirmText="Save"
        onDismissText="close"
        onDismiss={() => setShowInputModal(false)}
      />
      <ConfirmationModal
        title={`Are your sure you want to delete ${name}`}
        visible={showConfirmationModal}
        warnMassage="Note: You can not undo this action!"
        onConfirm={() => {
          deleteMeter(_id);
          navigation.goBack();
        }}
        onConfirmText="Yes"
        onDismissText="no"
        onDismiss={() => setShowConfirmationModal(false)}
      />
      {/* @ts-ignore */}
      {isEdit && (
        <CustomFAB
          icon="delete"
          color="white"
          bg={"red"}
          onPress={() => {
            setShowConfirmationModal(true);
          }}
        />
      )}
    </>
  );
};

export default EditMeter;
