import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {
  Text,
  TextInput,
  Title,
} from 'react-native-paper';


import { AvatarIcon } from '../components/avatar-icon';
import { Button } from '../components/buttons/custom-button';
import { LayoutWrapper } from '../components/layout-wrapper';

import { useAuth } from '../hooks/auth/useAuth';
import { useDesktopMediaQuery } from '../hooks/useIsDesktop';
import { colors } from '../theme/colors';
import { isPasswordEqualConfirmPassword } from '../utils/isConfPasswordEqual';

export const SignUp = ({ navigation }: any) => {
  const { _signUp } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);
  const [isSigningUp, setSigningUp] = useState(false);


  const { isDesktop } = useDesktopMediaQuery();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  return (
    <LayoutWrapper>
      <KeyboardAvoidingView
        behavior={
          Platform.OS === 'ios' ? 'padding' : 'height'
        }>
        <ScrollView>
          <View style={styles.mainContainer}>
            <View
              style={
                isDesktop
                  ? styles.keyboardAvoidingContainerDesktop
                  : styles.keyboardAvoidingContainer
              }>
              <View
                style={{
                  marginBottom: 10,
                }}>
                <AvatarIcon />
              </View>

              <View
                style={
                  isDesktop
                    ? styles.inputContainerDesktop
                    : undefined
                }>
                <Title
                  style={
                    isDesktop
                      ? styles.titleDesktop
                      : styles.title
                  }>
                  Sign Up
                </Title>
                <View>
                  <View
                    style={{
                      marginBottom: 20,
                    }}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        pattern:
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/,
                      }}
                      render={({
                        field: { onChange, value },
                      }) => (
                        <TextInput
                          mode='outlined'
                          placeholder='Email'
                          style={styles.input}
                          value={email}
                          clearButtonMode='always'
                          onChangeText={(character) => {
                            setEmail(character.trim());
                          }}
                          textContentType='emailAddress'
                        />
                      )}
                      name='email'
                    />
                    {errors.email?.type == 'required' ? (
                      <Text style={styles.errorText}>
                        Email is required.
                      </Text>
                    ) : (
                      errors.email?.type == 'pattern' && (
                        <Text style={styles.errorText}>
                          Email is not valid.
                        </Text>
                      )
                    )}
                  </View>
                  <View
                    style={{
                      marginBottom: 20,
                    }}>
                    <Controller
                      control={control}
                      name='password'
                      rules={{
                        required: true,
                        minLength: 5,
                      }}
                      render={({
                        field: { onChange, value },
                      }) => (
                        <TextInput
                          mode='outlined'
                          placeholder='Password'
                          style={styles.input}
                          value={password}
                          onChangeText={setPassword}
                          textContentType='password'
                          secureTextEntry={showPassword}
                          clearButtonMode='always'
                        />
                      )}
                    />
                    {errors.password?.type ===
                    'required' ? (
                      <Text style={styles.errorText}>
                        Password is required.
                      </Text>
                    ) : (
                      errors.password?.type ===
                        'minLength' && (
                        <Text style={styles.errorText}>
                          Password must be at least 8
                          characters.
                        </Text>
                      )
                    )}
                  </View>
                  <View
                    style={{
                      marginBottom: 10,
                    }}>
                    <Controller
                      control={control}
                      name='confirmPassword'
                      rules={{
                        required: true,
                        minLength: 8,
                        validate: (value: string) =>
                          isPasswordEqualConfirmPassword({
                            control,
                            value,
                          }),
                      }}
                      render={({
                        field: { onChange, value },
                      }) => (
                        <TextInput
                          mode='outlined'
                          placeholder='Confirm Password'
                          style={styles.input}
                          value={value}
                          onChangeText={onChange}
                          textContentType='password'
                          secureTextEntry={showPassword}
                          clearButtonMode='always'
                        />
                      )}
                    />
                    {errors.confirmPassword?.type ===
                    'required' ? (
                      <Text style={styles.errorText}>
                        Confirmation Password is required.
                      </Text>
                    ) : (
                      errors.confirmPassword?.type ===
                        'validate' && (
                        <Text>Password does not match</Text>
                      )
                    )}
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignContent: 'center',
                      marginBottom: 20,
                    }}>
                    <Pressable
                      // icon='eye'
                      style={{
                        borderColor: colors.primary,
                        borderWidth: 1,
                        padding: 3,
                        marginRight: 5,
                        borderRadius: 3,
                      }}
                      onPress={() => {
                        setShowPassword(!showPassword);
                      }}>
                      <View
                        style={{
                          backgroundColor: !showPassword
                            ? colors.primary
                            : undefined,
                          borderRadius: 3,

                          width: 16,
                          height: 16,
                        }}
                      />
                    </Pressable>
                    <Text
                      onPress={() => {
                        setShowPassword(!showPassword);
                      }}
                      style={{
                        color: colors.primary,
                        alignSelf: 'center',
                        fontSize: 16,
                      }}>
                      Show Password
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <Button
                    mode='outlined'
                    onPress={() => {
                      navigation.navigate('Login');
                    }}>
                    Login
                  </Button>
                  <Button
                    textColor='#fff'
                    mode='contained'
                    onPress={() => {
                      setSigningUp(true)
                      _signUp(email, password)}
                    }

                    loading={isSigningUp}
                    disabled={isSigningUp}>
                    Sign up
                  </Button>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </LayoutWrapper>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: '5%',
    marginVertical: '16%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  keyboardAvoidingContainer: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
  },

  keyboardAvoidingContainerDesktop: {
    width: '40%',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
  },
  inputContainerDesktop: {
    marginLeft: '30%',
  },
  input: {
    color: '#000',
    marginBottom: 4,
    backgroundColor: 'white',
  },

  loginButton: {
    width: 120,
    paddingVertical: 2,
    borderRadius: 4,
    borderColor: '#0DA789',
  },
  SignUpButton: {
    width: 120,
    paddingVertical: 2,
    borderRadius: 4,
    backgroundColor: '#0DA789',
    color: '#0DA789',
  },
  title: {
    paddingTop: 10,
    fontSize: 35,
    fontWeight: 'bold',
    marginBottom: 20,
    alignSelf: 'center',
    color: 'black',
  },
  titleDesktop: {
    paddingTop: 10,
    fontSize: 35,
    fontWeight: 'bold',
    marginBottom: 20,

    color: 'black',
  },

  errorText: {
    color: 'red',
  },
});
