import { sortBy } from 'lodash';

export const sortByDesc = (
  array: any[],
  picker: string
) => {
  console.log(array, picker);

  return sortBy(array, picker, 'desc');
};
