import React from 'react';
import {
  Image,
  Modal,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import ConfettiCannon from 'react-native-confetti-cannon';

import { colors } from '../theme/colors';

export const BadgesEarned = ({
  isEarned,
  badgeTitle,
  imageSrc,
  onClose,
  onConfirm,
}: {
  isEarned: boolean;
  badgeTitle: string;
  imageSrc: string;

  onClose: () => void;
  onConfirm: () => void;
}) => {

  if (!imageSrc && !badgeTitle) return;

  return (
    <>
      <Modal
        animationType='slide'
        transparent={true}
        visible={isEarned}
        onRequestClose={() => {
          onClose();
        }}>
        <View
          style={{
            position: 'absolute',
            zIndex: 1000,
            bottom: -20,
          }}>
          <ConfettiCannon
            count={200}
            autoStart
            origin={{ x: -10, y: 10 }}
          />
        </View>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View
              style={{
                display: 'flex',
                alignSelf: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: 10,
                marginBottom: 5,
              }}>
              <Image
                style={{
                  width: 300,
                  height: 300,
                  resizeMode: 'contain',
                  // opacity: !isEarned ? 0.3 : undefined,
                }}
                source={{
                  uri: imageSrc,
                }}
              />
              <View
                style={{
                  alignSelf: 'center',
                  width: '90%',
                }}>
                <Text
                  // numberOfLines={numOfLinesDescription || 2}
                  style={{
                    fontSize: 20,
                    fontWeight: '700',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    marginBottom: 5,
                  }}>
                  {badgeTitle}
                </Text>
                <Text
                  // numberOfLines={numOfLinesDescription || 2}
                  style={{
                    fontSize: 17,
                    fontWeight: '700',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    marginBottom: 5,
                  }}>
                  Congratulations You have Earned the{' '}
                  {badgeTitle} badge
                </Text>
              </View>
            </View>
            <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={onConfirm}>
              <Text style={styles.textStyle}>Ok</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    // alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    width: '100%',
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: colors.primary,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});
