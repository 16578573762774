import { createSlice } from '@reduxjs/toolkit';
import { Meters } from '../../../entities/Meters';

const initialState: {
  meters: Meters[]|null;
  currentMeter: Meters | null;
} = {
  currentMeter: null,
  meters: null,
};

export const meterSlice = createSlice({
  name: 'meter',
  initialState,
  reducers: {
    
    setMeters: (state, action) => {
     return {...state,meters:action.payload};
    },
    setCurrentMeter: (state, action) => {
     return {...state,currentMeter:action.payload};
    },
    
  },
});

export const {
  setMeters,
  setCurrentMeter,
} = meterSlice.actions;

export default meterSlice.reducer;
