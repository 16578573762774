import React, { memo, useState } from 'react';
import {StyleSheet, ScrollView, View, Pressable} from 'react-native';
import { List, MD3Colors, Modal, Portal, Text } from 'react-native-paper';
import { colors } from '../theme/colors';
import ChartComponent from './chart-component';
import { startCase } from "lodash";
import CustomIconButton from './buttons/icon-button';
import ChartKey from './chart-key-comp';
import { CommonStyles } from '../utils/common-styles';

//import { average } from '../utils/arrayAvarage';
export type GraphState =
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'quarterly';

const graphItems:string[]=['daily', 'weekly', 'monthly', 'quarterly']
const graphString={daily:'date', weekly:'Weeks', monthly:'Months', quarterly:'Quarters'}
const EnergyGraph = ({
  graphNavigationHandler,
  meterReadings,
  graphState,
  averageReadings,
  benchMarkPath,
  dataNav
}: {
  meterReadings: any;
  averageReadings:any;
  graphState:string;
  graphNavigationHandler: (item: GraphState) => void;
  benchMarkPath: (num: number) => void;
  dataNav: () => void;
}) => {
  const {average}=meterReadings
  
  const [visible, setVisible] = useState(false);
  const containerStyle = {backgroundColor: 'white', padding: 20};

  const hideDialog = () => setVisible(false);
   
    
  return (
    <View  style={{
      height: '76%',
      paddingBottom: "28%",
    }}>
    <ScrollView>
      

      <View style={{...CommonStyles.rowContainer,alignSelf: 'center'}}>
        {graphItems.map((value:string) => (
          <Pressable
            onPress={() => {
              graphNavigationHandler(value as GraphState);
            }}
            key={value}
            style={{
              ...styles.button,
              borderColor:
                value === graphState
                  ? 'black'
                  : colors.secondary,
              borderWidth: 1,

              backgroundColor:
                value === graphState
                  ? 'white'
                  : colors.secondary,
            }}>
            <Text
              style={{
                fontWeight: 'bold',
                color:
                  value === graphState
                    ? undefined
                    : 'white',
              }}>
              {startCase(value).split('')[0]}
            </Text>
          </Pressable>
        ))}
      </View> 
      <View style={CommonStyles.rowContainer}>
        <Text variant="labelSmall">
          kWh
        </Text>
        <View style={{...CommonStyles.rowContainer,width:95}}>

        <CustomIconButton
              icon='chart-line-stacked'
              pressFun={()=>setVisible(true)}
            />
        <CustomIconButton
              icon='application-export'
              pressFun={()=>dataNav(`${graphString[graphState]}`)}
            />
        </View>
      </View> 

      <ChartComponent
        {...{...meterReadings,averageReadings}}
        
      />
      <View style={{...CommonStyles.rowContainer}}>
        <View style={{
          width:'30%',
    
        }}>
        <ChartKey  color='#e26a00' title='Your Consumption'/>
        <ChartKey  color='blue' title='Sector consumption Average'/>
        
        </View>
        <View
          style={{
            marginRight: '4%',
            width:'60%',
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-end'
          }}>
            <Text variant="labelSmall">
                {`${graphString[graphState]}`}
              </Text>
              {average&&<Text
            style={{
              color: colors.primary,
              fontSize: 18,
              marginBottom: 20,
            }}>
          {`Average ${average} kWh`}
          </Text>}
        </View>
      </View>
      
      <Portal>
      <Modal visible={visible} onDismiss={hideDialog} contentContainerStyle={containerStyle}>
      <List.Section>
    <List.Subheader>Benchmark</List.Subheader>
    <List.Item onPress={()=>{
      benchMarkPath(0)
      hideDialog()
      }}  title="Countrywide" left={() => <List.Icon icon="map-marker-question" />} />
    <List.Item  onPress={()=>{
      benchMarkPath(1)
      hideDialog()
    }} 
      title="Provice"
      left={() => <List.Icon color={MD3Colors.tertiary70} icon="map-marker-question" />}
    />
    <List.Item  onPress={()=>{
      benchMarkPath(2)
      hideDialog()
      }}   title="Town/City" left={() => <List.Icon icon="map-marker-question" />} />
  </List.Section>
        </Modal>
    </Portal>
    </ScrollView>
    </View>
  );
};


export default memo(EnergyGraph)
const styles = StyleSheet.create({
  
  button: {
    marginRight: 3,
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 3,
  },
});
