import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Pressable, StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Text, TextInput } from 'react-native-paper';
import { Button } from '../../components/buttons/custom-button';
import { CustomFAB } from '../../components/buttons/custom-fab';
import  ConfirmationModal  from '../../components/dialog/confirmation';
import { ScreenTitleWrapper } from '../../components/screen-title-wrapper';
import { useAuth } from '../../hooks/auth/useAuth';
import { useMe } from '../../hooks/useMe';
import { colors } from '../../theme/colors';

const Profile = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [isUserUpdating, setUserUpdating] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState(false);
  const [
    showEmailVerificationModal,
    setShowEmailVerificationModal,
  ] = useState(false);

  const {
    user: me,
    _updateUser,
    deleteCurrentUser,
  } = useMe();

  const { _sendEmailVerification } = useAuth();

  

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: me?.displayName || '',
      email: me?.email || '',
      password: '',
    },
  });

  return (
    <>
      <ScreenTitleWrapper
        title='Profile'
        subTitle={me?.displayName || ''}>
        <ScrollView>
          <View
            style={{
              marginTop: 30,
              width: '70%',
              alignSelf: 'center',
            }}>
            {!me?.displayName ? (
              <Text
                style={{
                  color: colors.secondary,
                  fontWeight: 'bold',
                  fontSize: 15,
                }}>
                Your account is 70% done add a user name.
              </Text>
            ) : !me?.emailVerified ? (
              <>
                <Text
                  style={{
                    color: colors.secondary,
                    fontWeight: 'bold',
                    fontSize: 15,
                  }}>
                  Your account is 80% done verify your
                  email.
                </Text>
                <ConfirmationModal
                  title='Verification Email Sent to your account.'
                  visible={showEmailVerificationModal}
                  massage="If you don't see it check your spam folder."
                  hasCancel={false}
                  onConfirm={() =>
                    setShowEmailVerificationModal(false)
                  }
                  onDismiss={() =>
                    setShowEmailVerificationModal(false)
                  }
                />
                <Pressable
                  onPress={() => {
                    _sendEmailVerification(me).then(() => {
                      setShowEmailVerificationModal(true);
                    });
                  }}>
                  <Text
                    style={{
                      color: colors.primary,
                      fontWeight: 'bold',
                      fontSize: 15,
                    }}>
                    Click here to resend verification email
                  </Text>
                </Pressable>
              </>
            ) : null}
            <View style={{ marginBottom: 20 }}>
              <Controller
                name='name'
                rules={{
                  required: true,
                  minLength: 4,
                }}
                control={control}
                render={({
                  field: { onChange: handleChange, value },
                }) => (
                  <TextInput
                    mode='outlined'
                    label={'Name'}

                    placeholder='Change Name'
                    value={value}
                    onChangeText={handleChange}
                    textContentType='username'
                  />
                )}
              />
              {errors.name?.type == 'required' ? (
                <Text style={styles.errorText}>
                  Name is required.
                </Text>
              ) : (
                errors.name?.type == 'minLength' && (
                  <Text style={styles.errorText}>
                    Name should be a min of 4 characters
                    long.
                  </Text>
                )
              )}
            </View>

            <View style={{ marginBottom: 20 }}>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: true,
                  pattern:
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/,
                }}
                render={({
                  field: { onChange, value },
                }) => (
                  <TextInput
                    mode='outlined'
                    label={'Email'}

                    placeholder='Change email'
                    value={value}
                    onChangeText={onChange}
                    textContentType='emailAddress'
                  />
                )}
              />
              {errors.email?.type == 'required' ? (
                <Text style={styles.errorText}>
                  email is required.
                </Text>
              ) : (
                errors.email?.type == 'minLength' && (
                  <Text style={styles.errorText}>
                    Name should be a min of 4 characters
                    long.
                  </Text>
                )
              )}
            </View>
            <View style={{ marginBottom: 20 }}>
              <Controller
                name='password'
                control={control}
                rules={{
                  minLength: 6,
                }}
                render={({
                  field: { onChange, value },
                }) => (
                  <TextInput
                    mode='outlined'
                    label={'Password'}

                    placeholder='Change Password'
                    value={value}
                    onChangeText={onChange}
                    textContentType='password'
                    right={
                      <TextInput.Icon
                        onPress={() => {
                          setShowPassword(!showPassword);
                        }}
                        // @ts-ignore
                        icon='eye'
                      />
                    }
                    secureTextEntry={showPassword}
                  />
                )}
              />
              {errors.password?.type == 'minLength' && (
                <Text style={styles.errorText}>
                  Password should be a min of 6 characters
                  long.
                </Text>
              )}
            </View>
            <View
              style={{
                marginBottom: 21,
              }}>
              <Button
                mode='contained'
                onPress={handleSubmit(async(data) => {
                  // TODO*: add dispatcher for user data
                  // dispatch(addMeter({ ...data, id: 4534 }));
                  // () => {
                    setUserUpdating(true)
                    await _updateUser(data);
                    setUserUpdating(false)

                  // navigation.navigate('Home');
                  // };
                })}
                loading={isUserUpdating}
                disabled={isUserUpdating}>
                Save
              </Button>
            </View>
          </View>
        </ScrollView>
      </ScreenTitleWrapper>

      <ConfirmationModal
        title='Delete Account'
        visible={showConfirmationModal}
        massage='Are You sure you want to delete your account?'
        onConfirm={deleteCurrentUser}
        onDismiss={() => setShowConfirmationModal(false)}
      />
      {/* @ts-ignore */}
      <CustomFAB
      icon='delete'
      color='white'
      bg={'red'}
        onPress={() => setShowConfirmationModal(true)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidingContainer: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  keyboardAvoidingContainerDesktop: {
    width: '40%',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
  },
  inputContainerDesktop: {
    marginLeft: '30%',
  },
  input: {
    color: '#000',
    backgroundColor: 'white',
    marginBottom: 4,
  },

  loginButton: {
    width: 120,
    paddingVertical: 2,
    borderRadius: 4,
    backgroundColor: '#0DA789',
    color: 'white',
  },
  SignUpButton: {
    width: 120,
    paddingVertical: 2,
    borderRadius: 4,
    borderColor: '#0DA789',
    color: 'white',
  },
  title: {
    paddingTop: 10,
    fontSize: 35,
    fontWeight: 'bold',
    marginBottom: '20%',
    alignSelf: 'center',
    color: 'black',
  },
  titleDesktop: {
    paddingTop: 10,
    fontSize: 35,
    fontWeight: 'bold',
    marginBottom: 20,

    color: 'black',
  },
  errorText: {
    color: 'red',
  },
});
export default Profile;
