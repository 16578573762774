// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
//dev

// export const firebaseConfig = {
//   apiKey: "AIzaSyD28LYPl98sSmas30fz6aMlKuVrgk9HiZk",
//   authDomain: "enerygy-meter-dev.firebaseapp.com",
//   projectId: "enerygy-meter-dev",
//   storageBucket: "enerygy-meter-dev.appspot.com",
//   messagingSenderId: "340539196637",
//   appId: "1:340539196637:web:421401de9e45877b5d4734",
//   measurementId: "G-090B37H1PN"
// };

// live
export const firebaseConfig = {
  apiKey: 'AIzaSyBxo983W8OZ2U86OLHSoBieInlZIgwM_qk',
  authDomain: 'energy-meter-app-4577e.firebaseapp.com',
  databaseURL:
    'https://energy-meter-app-4577e-default-rtdb.firebaseio.com',
  projectId: 'energy-meter-app-4577e',
  storageBucket: 'energy-meter-app-4577e.appspot.com',
  messagingSenderId: '135368893417',
  appId: '1:135368893417:web:19070bbe76ea6793a5e178',
  measurementId: 'G-FDQH963WJD',
};

// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig);
export default FirebaseApp;
