import { View } from 'react-native';
import { Divider, List, Text } from 'react-native-paper';
import { LayoutWrapper } from '../components/layout-wrapper';
import { ScreenTitleWrapper } from '../components/screen-title-wrapper';
import React from 'react';
import { colors } from '../theme/colors';
import { ScrollView } from 'react-native-gesture-handler';
const Tips = () => {
  return (
    <ScreenTitleWrapper
      title='Tips'
      subTitle={'Get useful Tips'}>
      <LayoutWrapper>
        <ScrollView
          style={{
            marginHorizontal: 10,
          }}>
          <View
            style={{
              paddingBottom: 100,
            }}>
            <List.Section
              titleStyle={{
                color: colors.primary,
                fontWeight: 'bold',
                fontSize: 25,
              }}
              title='Energy Efficiency Tips'>
              <List.Accordion
                titleStyle={{
                  padding: 0,
                }}
                title='What does energy efficiency mean?'>
                <List.Item
                  descriptionStyle={{
                    margin: 0,
                  }}
                  description='This means using less energy to get the same job done.'
                  descriptionNumberOfLines={5}
                  title
                />
              </List.Accordion>
              <List.Accordion title='How can I save power and money?'>
                <List.Item
                  style={{
                    flexWrap: 'wrap',
                  }}
                  description='Manage your power usage by using energy efficient appliances such as energy saving bulbs and solar geysers.'
                  descriptionNumberOfLines={12}
                  title={null}
                />
              </List.Accordion>
              <List.Accordion
                title='How can you contact a Certified Energy Auditor?'
                titleNumberOfLines={2}>
                <List.Item
                  style={{
                    flexWrap: 'wrap',
                  }}
                  description='You can access or contact a Certified Energy Auditor at the EIZ in Lusaka on +260 211 255161 or email: eiz@eiz.org.zm'
                  descriptionNumberOfLines={12}
                  title={null}
                />
              </List.Accordion>
            </List.Section>
          </View>
        </ScrollView>
      </LayoutWrapper>
    </ScreenTitleWrapper>
  );
};
export default Tips;
