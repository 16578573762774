import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import  { useCallback, useState } from 'react';
import { Alert } from 'react-native';
import { useDispatch } from 'react-redux';
import FirebaseApp from '../../config/firebase';
import {
  isNotLoading,
  login,
  logOut,
  setUser,
  toggleIsLoadingIn,
  toggleIsResettingPassword,
  toggleIsSigningUp,
} from '../../data/redux/slices/login';
import { Person } from '../../entities/Person';

type UserLogin = Pick<Person, 'email' | 'password'>;


const setUserToken = async () => {
  await AsyncStorage.setItem('authToken', 'token');
};

export const useAuth = () => {
  const firebaseAuth = getAuth(FirebaseApp);

  const getCurrentUser = firebaseAuth;

  const dispatch = useDispatch();

  const [confirm, setConfirm] = useState<any>(null);

  const [code, setCode] = useState('');

  const [initializing, setInitializing] = useState(true);

  // Listen for authentication state to change.
  onAuthStateChanged(firebaseAuth, (user) => {
    if (initializing) setInitializing(false);

    // Do other things
    return { user };
  });

  async function _confirmCode() {
    try {
      await confirm.confirm(code);
    } catch (error) {
      console.log('Invalid code.');
    }
  }

  const _login = useCallback(async (data: UserLogin) => {
    try {
      dispatch(toggleIsLoadingIn());
      const confirmation = await signInWithEmailAndPassword(
        firebaseAuth,
        data.email,
        data.password
      );
      // setConfirm(confirmation);
      console.log(
        '====================================',
        confirmation
      );
      // console.log(data);
      setUserToken();
      dispatch(login());
      console.log('====================================');
    } catch (error) {
      Alert.alert('Error', 'Sorry wrong password or email');
      console.log('====================================');
      console.log(error);
      console.log('====================================');
      dispatch(toggleIsLoadingIn());
    }
  }, []);
  const _restPasswordAccount = useCallback(
    async (data: Pick<UserLogin, 'email'>) => {
      dispatch(toggleIsResettingPassword());
      await sendPasswordResetEmail(firebaseAuth, data.email)
        .then(() => {
          dispatch(toggleIsResettingPassword());
          console.log(
            '===================================='
          );
          console.log('reset  email sent', data.email);
          console.log(
            '===================================='
          );
        })
        .catch((error) => {
          dispatch(toggleIsResettingPassword());
          console.log(
            '===================================='
          );
          Alert.alert('Error', 'Sorry wrong email');
          console.log(error);
          console.log(
            '===================================='
          );
        });
      // setConfirm(confirmation);
    },
    []
  );

  const _sendEmailVerification = useCallback(
    async (user: Person) => {
      await sendEmailVerification(user)
        .then((data) => {
          console.log(
            '===================================='
          );
          console.log('reset  email sent', data?.email);
          console.log(
            '===================================='
          );
        })
        .catch((error) => {
          console.log(
            '===================================='
          );
          console.log(error);
          console.log(
            '===================================='
          );
        });

      // setConfirm(confirmation);
    },
    []
  );
  const _signUp = useCallback(
    async (email: string, password: string) => {
      try {
        dispatch(toggleIsSigningUp());
        const confirmation =
          await createUserWithEmailAndPassword(
            firebaseAuth,
            email,
            password
          );

        if (confirmation.user) {
          _login({ email, password });
          _sendEmailVerification(confirmation.user).then(
            () => {
              Alert.alert(
                'Verification Email Sent to your account',
                "If you don't see it check your spam folder"
              );
            }
          );
        }
        // Alert.alert('Account Created!');
        console.log('_signUp:confirmation', confirmation);

        // dispatch(login());
      } catch (error) {
        dispatch(toggleIsSigningUp());
        Alert.alert('Email already in use');
        console.log('_signUp:err', error);
      }
    },
    []
  );
  const _logOut = useCallback(async () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        dispatch(setUser({}));

        dispatch(logOut());
      })
      .catch((error) => {
        console.log('_logOut:err', error);
      });
  }, []);
  const _hasAuth = useCallback(async () => {
    const auth = getAuth().currentUser;
    try {
      const hasToken = Boolean(auth);
      if (hasToken) {
        dispatch(login());
      }
      dispatch(isNotLoading());
      console.log('_haseToken', auth);
    } catch (error) {
      // throw new Error(error);
    }
  }, []);

  return {
    _login,
    _logOut,
    _signUp,
    _restPasswordAccount,
    _sendEmailVerification,
    _hasAuth,
    initializing,
    _confirmCode,
    getCurrentUser,
  };
};
