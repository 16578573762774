export const meterRules = {
  name: {
    required: true,
    minLength: 4,
  },
  number: {
    required: true,
    minLength: 8,
  },
  average: {
    required: true,
    minLength: 1,
  },
  category: {
    required: true,
  },
  appliances: {
    required: true,
  },
  country: {
    required: true,
  },
  location: {
    required: true,
  },
  town: {
    required: true,
  },
};
