import React,{useCallback,useMemo,useState,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import  BadgeComp  from '../components/badge-comp';
import { LayoutWrapper } from '../components/layout-wrapper';
import { LoadingComp } from '../components/loading-comp';
import { ScreenTitleWrapper } from '../components/screen-title-wrapper';
import { RootState } from '../data/redux/store';
import useFBDocs from '../hooks/useFBDocs';
import { unionBy,orderBy } from "lodash";
import { badgesChanged } from '../data/redux/slices/badges';
import { FlatList } from 'react-native-gesture-handler';

const Badges = () => {
  const { badges } = useSelector(
    (state: RootState) => state.badge
  );
  const dispatch=useDispatch()
  const mainRef=useRef()
  const [searchData,setSearchData]=useState<any>()

  const badgeCallback = useCallback((data:any) => {

    console.log('data inner ',data.length);
    if(data.length>0){
      dispatch(badgesChanged(unionBy( orderBy(data,['priority'],['asc']),mainRef.current, '_id')))
    }
    
  
  }, []);

  useFBDocs(searchData, badgeCallback);
  const mainBadgesData=useMemo(() => {
    return {
      path:`mainBadges`, 

    }
  }, [])

  const mainBadgeCallback = useCallback((data:any) => {

    console.log('data mainBadgeCallback ',data.length);
    if(data.length>0){
      mainRef.current=data[0].badges
      //dispatch(badgesChanged(unionBy( data[0].badges, badges,'_id')))
      setSearchData((prevState)=>{
        return{
        path:`users/<uid>/badges`, 
        reload:prevState?!prevState?.reload:true
      }})
    }
    
  
  }, []);

  useFBDocs(mainBadgesData, mainBadgeCallback);


  if (badges.length===0) return <LoadingComp />;

  return (
    <ScreenTitleWrapper title='Badges'>
      <LayoutWrapper>
      {badges.length>0&&<FlatList
        style={{
          paddingBottom: 100,
        }}
        data={badges}
        keyExtractor={(item) => {
          return item._id;
        }}
        extraData={{
          badges,
        }}
        snapToAlignment='center'
        
        renderItem={({item}) =>  <BadgeComp
            key={item?._id}
            image={item?.imageUrl}
            title={item?.title}
            description={item?.description}
            isEarned={item?.isEarned}
          />
        }
        
    
      />}
        
      </LayoutWrapper>
    </ScreenTitleWrapper>
  );
};

export default Badges;
