import React,{useState,useEffect,useRef,useCallback} from 'react'
import { ScreenTitleWrapper } from '../components/screen-title-wrapper'
import { flatten,startCase,orderBy } from "lodash";
import { LoadingComp } from '../components/loading-comp';
import { FlatList,View } from 'react-native';
import { Chip } from 'react-native-paper';
import Prompts from '../components/prompts';
import DataListView from '../components/consumption-list-view';
import SummaryDataListView from '../components/summary-list-view';
import { makeId } from '../utils/randomId';
import useFBDocs from '../hooks/useFBDocs';
import * as XLSX from 'xlsx'
import * as FileSystem from 'expo-file-system'
import * as Sharing from 'expo-sharing'
import { format } from 'date-fns';

const DataView = ({route}) => {
    const {currentReports,firstCol,date,meterId,meterName,summary,search}=route.params
    const mounted = useRef<boolean>(false);
    const [parsedReports, setparsedReports] = useState<any>(null)
    console.log('parsedReports ',parsedReports);

    const getReportArray=(reports:any[])=>{
      const {type,payload}=reports
        const items=payload.reduce((cal:any,val:any)=>{
            const element=val[meterId]
            if(element){
              const {entries}=element
              return [...cal,{
                _id:makeId(),
                type,
                values:flatten(entries.reduce((docs,{copyDocs})=>{
                return orderBy([...docs,...copyDocs],['dx'],['asc'])
              },[]))}]
            }else{
              return cal
            }
            
          },[])
        console.log('items ',items);
        //setparsedReports(null)
          const {type:cast,payload:{readingsData}}=summary
          console.log('values ',readingsData,' type ',type);
          
          setparsedReports([{_id:makeId(),values:readingsData,type:cast},{_id:makeId(),type:2,label:'Dive Deep'},...items])
       
    }
    const generateExcel=(entities:any[],type:number)=>{
        //console.log('format(entities[0]?.createdAt?.toDate() ',format(entities[0]?.createdAt?.toDate(),'ddMMyyyy'));
        
        const workbook = XLSX.utils.book_new();
        let aoa
        if(type===0){

          aoa=entities.reduce((cal,{x,y=0})=>{
             return [...cal,[x,y]]
         },[[`${firstCol}`,'Consumption']])
        }else{

          aoa=entities.reduce((cal,{createdAt,meterData,lastTopUp,diff=0})=>{
             return [...cal,[format(createdAt?.toDate(),'dd/MM/yyyy'),format(createdAt?.toDate(),'HH:mm'),meterData,lastTopUp,diff]]
         },[['Date','Time','Readings','TopUp','Consumption']])
        }
        const worksheet = XLSX.utils.aoa_to_sheet(aoa);
        XLSX.utils.book_append_sheet(workbook, worksheet, `consumption`,true);
        const base64=XLSX.write(workbook,{type:'base64'})
        const filename=FileSystem.documentDirectory+`consumption_${date?date.replace('/','').replace('/','').replace('/',''):format(entities[0]?.createdAt?.toDate(),'ddMMyyyy')}.xlsx`
        FileSystem.writeAsStringAsync(filename,base64,{encoding:FileSystem.EncodingType.Base64}).then(()=>{
            Sharing.shareAsync(filename)
        })
    }
    const readingCallback = useCallback((data:any, posts:any,col:string) => {

        console.log('isCompelete total outer ');
        if(mounted.current){
    
          console.log('isCompelete total ', data, 'job_posts ', col);
         
          getReportArray({payload:data,type:1})
    
        }   
    }, []);
    useFBDocs(search, readingCallback);
    useEffect(() => {
        mounted.current = true;
        if(currentReports){
            getReportArray(currentReports)
        }
        return () => {
          mounted.current = false;
        };
    }, [currentReports]);
    if (!parsedReports) {
      return <LoadingComp />;
    }
  return (
    <ScreenTitleWrapper title='Consumption Data' subTitle={startCase(meterName)}>
          <View
            style={{
              height: '86%',
              paddingBottom: "10%",
            }}>
            <FlatList
              data={parsedReports}
              keyExtractor={(item) => {
                return item._id;
              }}
              extraData={{ parsedReports }}
              snapToAlignment='center'
              onEndReachedThreshold={0.5}
              ListEmptyComponent={() => (
                <Prompts
                  title={`No Data`}
                  height='100%'

                />
                
              )}
              renderItem={({item:{values,type,label}})=>{
                if(type===0){

                  return <SummaryDataListView {...{firstCol,date}} entries={values} generateExcel={generateExcel}/>
                }else if(type===2){
                  return <Chip>{label}</Chip>
                }else{

                  return <DataListView entries={values} generateExcel={generateExcel}/>
                }
              }}
              
            />
          </View>
        
    </ScreenTitleWrapper>
  )
}

export default DataView