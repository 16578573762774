import React from 'react';

import { AppStack } from './navigation/app-stack';
import { AuthStack } from './navigation/auth-stack';
import AuthIsLoaded from './AuthIsLoaded';
import { NavigationContainer } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { RootState } from './data/redux/store';
import { isEmpty } from 'lodash';
const AppContainer = ({ appIsReady, SplashScreen }) => {
  const { user } = useSelector(
    (state: RootState) => state.login
  );
  return (
    <AuthIsLoaded {...{ appIsReady, SplashScreen }}>
      <NavigationContainer>
        {isEmpty(user) ? (
          <AuthStack />
        ) : (
          <AppStack />
        )}
      </NavigationContainer>
    </AuthIsLoaded>
  );
};

export default AppContainer;
