import React,{memo} from 'react'
import { Record } from '../entities/Record';
import { format } from 'date-fns';
import { CommonStyles } from '../utils/common-styles';
import CustomIconButton from '../components/buttons/icon-button';
import { View } from 'react-native';
import { Text, Title } from 'react-native-paper';
import { round } from 'lodash';

const clrArray = ['green', 'yellow', 'red'];
const RecordItem = ({
  item,
  navFun,
  index
}: {
  item: Record;
  navFun:()=>void;
  index:Number;
}) => {
  // const { item, navigation } = props;
  const time = format(
    new Date(item?.createdAt),
    'dd MMM yyyy HH:mm:ss'
  ); /* timeConverter(new Date(item?.createdAt).getTime()) */
  console.log('color ', index);

  return (
    <View
      
      style={{
        display: 'flex',
        borderWidth: 1,
        marginBottom: 10,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
      }}>
        <View style={{...CommonStyles.rowContainer,paddingHorizontal: 0,}}>
        <Text>{time}</Text>
            
            {index===0&&<CustomIconButton
                  icon='pencil-outline'
                  pressFun={navFun}
                />}
      </View> 
      
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Title
          numberOfLines={1}
          style={{
            width: '40%',
            fontWeight: 'bold',
          }}>
          {round(item.meterData, 2)}
        </Title>

        <View
          style={{
            borderRadius: 100,
            height: 10,
            width: 10,
            backgroundColor:
              item.color !== undefined
                ? clrArray[item.color]
                : 'rgba(0,0,0,0)',
          }}
        />

        <Text
          numberOfLines={1}
          style={{
            width: '30%',
          }}>
          {item?.description}
        </Text>
      </View>

      <View
        style={{
          borderRadius: 100,
          flex: 1,
          width: '100%',
          backgroundColor: '#EBEBEB',
        }}>
        <Text
          numberOfLines={1}
        >
          Top Up: {item?.lastTopUp || 0} Units
        </Text>
      </View>
    </View>
  );
};
export default memo(RecordItem)