import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  KeyboardAvoidingView,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import {
  Text,
  TextInput,
  Title,
} from 'react-native-paper';
import { useSelector } from 'react-redux';
import { AvatarIcon } from '../components/avatar-icon';
import { Button } from '../components/buttons/custom-button';
import { LayoutWrapper } from '../components/layout-wrapper';

import { RootState } from '../data/redux/store';
import { useAuth } from '../hooks/auth/useAuth';
import { useDesktopMediaQuery } from '../hooks/useIsDesktop';
import { colors } from '../theme/colors';


export const Login = ({ navigation }: any) => {
  const [showPassword, setShowPassword] = useState(true);
  const { isLoadingIn } = useSelector(
    (state: RootState) => state.login
  );
  const { _login, initializing } = useAuth();
  const { isDesktop } = useDesktopMediaQuery();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  if (initializing) return null;

  return (
    <LayoutWrapper>
      <View style={styles.mainContainer}>
        <KeyboardAvoidingView
          behavior={
            Platform.OS === 'ios' ? 'padding' : 'height'
          }
          style={
            isDesktop
              ? styles.keyboardAvoidingContainerDesktop
              : styles.keyboardAvoidingContainer
          }>
          <AvatarIcon />
          <View
            style={
              isDesktop
                ? styles.inputContainerDesktop
                : undefined
            }>
            <Title
              style={
                isDesktop
                  ? styles.titleDesktop
                  : styles.title
              }>
              Sign In
            </Title>
            <View>
              <View
                style={{
                  marginBottom: 20,
                }}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    pattern:
                      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/,
                  }}
                  render={({
                    field: { onChange, value },
                  }) => (
                    <TextInput
                      mode='outlined'
                      placeholder='Enter Your Email'
                      style={styles.input}
                      value={value}
                      onChangeText={(character) => {
                        return onChange(character.trim());
                      }}
                      clearButtonMode='always'
                      textContentType='emailAddress'
                    />
                  )}
                  name='email'
                />
                {errors.email?.type == 'required' ? (
                  <Text style={styles.errorText}>
                    Email is required.
                  </Text>
                ) : (
                  errors.email?.type == 'pattern' && (
                    <Text style={styles.errorText}>
                      Email is not valid.
                    </Text>
                  )
                )}
              </View>

              <View
                style={{
                  marginBottom: 40,
                }}>
                <Controller
                  control={control}
                  name='password'
                  rules={{
                    required: true,
                    minLength: 6,
                  }}
                  render={({
                    field: { onChange, value },
                  }) => (
                    <TextInput
                      mode='outlined'
                      placeholder='Enter Your Password'
                      style={styles.input}
                      value={value}
                      onChangeText={onChange}
                      textContentType='password'
                      clearButtonMode='always'
                      right={
                        <TextInput.Icon
                          onPress={() => {
                            setShowPassword(!showPassword);
                          }}
                          // @ts-ignore
                          icon='eye'
                        />
                      }
                      secureTextEntry={showPassword}
                    />
                  )}
                />
                {errors.password?.type === 'required' ? (
                  <Text style={styles.errorText}>
                    Password is required.
                  </Text>
                ) : (
                  errors.password?.type === 'minLength' && (
                    <Text style={styles.errorText}>
                      Password must be at least 6
                      characters.
                    </Text>
                  )
                )}
                <Pressable
                  onPress={() => {
                    navigation.navigate('ForgotPassword');
                  }}>
                  <Text
                    style={{
                      color: colors.primary,
                      marginTop: 5,
                    }}>
                    Forgot Password?
                  </Text>
                </Pressable>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <Button
                mode='outlined'
                onPress={() => {
                  navigation.navigate('SignUp');
                }}>
                Sign up
              </Button>
              <Button
                mode='contained'
                onPress={handleSubmit(_login)}
                loading={isLoadingIn}
                disabled={isLoadingIn}
                textColor='#fff'>
                Login
              </Button>
            </View>
          </View>
        </KeyboardAvoidingView>
      </View>
    </LayoutWrapper>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    height: '100%',
    marginHorizontal: '5%',
    marginTop: Platform.OS === 'web' ? '10%' : undefined,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  keyboardAvoidingContainer: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  keyboardAvoidingContainerDesktop: {
    width: '40%',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
  },
  inputContainerDesktop: {
    marginLeft: '30%',
  },
  input: {
    color: '#000',
    backgroundColor: 'white',
    marginBottom: 4,
  },
  title: {
    paddingTop: 10,
    fontSize: 35,
    fontWeight: 'bold',
    marginBottom: 20,
    alignSelf: 'center',
    color: 'black',
  },
  titleDesktop: {
    paddingTop: 10,
    fontSize: 35,
    fontWeight: 'bold',
    marginBottom: 20,

    color: 'black',
  },
  errorText: {
    color: 'red',
  },
});
