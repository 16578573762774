import { useMediaQuery } from 'react-responsive';
export const useDesktopMediaQuery = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1280px)',
  });

  return {
    isDesktop,
  };
};
