import { unionBy } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { Dropdown } from 'react-native-element-dropdown';
import { ScreenTitleWrapper } from '../components/screen-title-wrapper';
import { LoadingComp } from '../components/loading-comp';
import useFBDocs from '../hooks/useFBDocs';
import Prompts from '../components/prompts';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../data/redux/store';
import { setCurrentMeter } from '../data/redux/slices/meter';
import RecordItem from '../components/record-item';
import { CustomFAB } from '../components/buttons/custom-fab';
import { colors } from '../theme/colors';
import { addMeterDefaultValues } from './meters/constants';

const AllData = () => {
	// const allData = useRecords()._getAllRecords();
	const navigation = useNavigation();
	const [refreshing, setRefreshing] = React.useState(false);

	const [currentMeterRecords, setCurrentMeterRecords] = useState<any>([]);
	console.log('====================================');
	console.log(
		currentMeterRecords.map((i) => {
			return i._id;
		}),
	);
	console.log('====================================');
	const reform = useRef<any>({
		path: `users/<uid>/meter-readings`,
		boolValue: {
			value: 'isArchived',
			key: false,
		},
		lim: 15,
	});
	const mounted = useRef<boolean>(false);
	const [searchReadings, setSearchReadings] = useState<any>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isSpinner, setSpinner] = useState<boolean>(true);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const { meters, currentMeter } = useSelector(
		(state: RootState) => state.meter,
	);
	const handleRefresh = () => {
		setRefreshing((prevState) => !prevState);
	};
	const dispatch = useDispatch();
	/* const metersList =useMemo(()=>meters.reduce((cal:any,item: any) => {
    return [...cal,{
      _id: item?._id,
      value: item.name,
    }];
  },[]),[meters]) */
	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false;
		};
	}, []);

	const readingCallback = useCallback((data: any, posts: any) => {
		if (mounted.current) {
			setLoading(false);
			setSpinner(false);
			console.log('isCompelete total ', data, 'job_posts ', posts.length);
			setCurrentMeterRecords([]);
			setCurrentMeterRecords(unionBy([...data, ...posts], '_id'));

			setHasMore(data.length > 0);
		}
	}, []);
	useFBDocs(searchReadings, readingCallback);
	const startSearch = useCallback(
		(form: any, posts: any, lastDoc: any, meterId: string) => {
			console.log('startSearch useCallback ', meterId);
			setLoading(true);
			setSearchReadings(null);
			setSearchReadings({
				...form,
				meterId,
				posts,
				lastDoc,
			});
		},
		[],
	);
	const loadMore = () => {
		if (currentMeterRecords.length > 0 && !isLoading && hasMore) {
			setLoading(true);
			console.log('startSearch loadMore ', currentMeterRecords.length);

			if (currentMeter) {
				startSearch(
					reform.current,
					currentMeterRecords,
					currentMeterRecords.length > 0
						? currentMeterRecords[currentMeterRecords.length - 1]._id
						: null,
					currentMeter?._id,
				);
			}
		}
	};
	useFocusEffect(
		useCallback(() => {
			console.log('currentMeter ', currentMeter);

			if (currentMeter) {
				setCurrentMeterRecords([]);
				setSpinner(true);

				startSearch(reform.current, [], null, currentMeter?._id);
			} else {
				setSpinner(false);
			}
		}, [startSearch, currentMeter]),
	);
	console.log(navigation);

	// if (meters.length <= 0) {
	//   return <LoadingComp />;
	// }

	if (isSpinner) {
		return <LoadingComp />;
	}
	return (
		<>
			<ScreenTitleWrapper title='Meter Readings'>
				<View
					style={{
						width: '80%',
						alignSelf: 'center',
					}}
				>
					{meters && meters.length > 0 ? (
						<>
							<Dropdown
								placeholder='Select Meter'
								style={styles.dropdown}
								containerStyle={styles.dropdownContainer}
								itemContainerStyle={styles.dropdownItemContainer}
								onChange={(item) => {
									dispatch(setCurrentMeter(item));
								}}
								data={meters}
								valueField='name'
								labelField='name'
								value={currentMeter}
							/>
							<View
								style={{
									height: '86%',
									paddingBottom: '28%',
								}}
							>
								<FlatList
									data={currentMeterRecords}
									keyExtractor={(item) => {
										return item._id;
									}}
									extraData={{
										currentMeterRecords,
									}}
									snapToAlignment='center'
									onEndReachedThreshold={0.5}
									ListEmptyComponent={() => (
										<Prompts
											title={`No readings for ${
												currentMeter?.name || meters[0]?.name
											}`}
											step1={`Add Readings here`}
											height='100%'
											nav1={{ name: 'AddReadings' }}
										/>
									)}
									onEndReached={loadMore}
									renderItem={({ item, index }) => (
										<RecordItem
											{...{ item, index }}
											navFun={() =>
												navigation.navigate('EditReadings', {
													current: currentMeterRecords[0],
													prev:
														currentMeterRecords.length > 1
															? currentMeterRecords[1]
															: null,
												})
											}
										/>
									)}
									refreshing={refreshing}
									onRefresh={handleRefresh}
								/>
							</View>
						</>
					) : meters && meters.length <= 0 ? (
						<Prompts
							title='Step 1'
							step1='Create a meter on the meter tab.'
							height='100%'
							nav1={{
								name: 'Meters',
								screen: 'AddMeter',
								params: addMeterDefaultValues,
							}}
						/>
					) : currentMeterRecords.length <= 0 ? (
						<Prompts
							title='Step 2'
							step1='Add meter readings on the here.'
							height='100%'
							nav1={{ name: 'AddReadings' }}
						/>
					) : null}
				</View>
			</ScreenTitleWrapper>
			{meters && meters.length >= 1 && (
				<CustomFAB
					icon='plus'
					color='white'
					bg={colors.primary}
					onPress={() => {
						navigation.navigate('AddReadings', {
							prev: currentMeterRecords ? currentMeterRecords[0] : null,
						});
					}}
				/>
			)}
		</>
	);
};

export default AllData;

const styles = StyleSheet.create({
	dropdown: {
		backgroundColor: '#ffffff',
		width: '70%',
		alignSelf: 'center',
		borderColor: '#000',
		borderWidth: 1,
		paddingVertical: 5,
		borderRadius: 4,
		paddingHorizontal: 10,
		marginBottom: 10,
	},
	dropdownContainer: {
		borderColor: '#000',
		borderWidth: 1,
		borderRadius: 4,
	},
	dropdownItemContainer: {
		borderTopWidth: 1,
		borderColor: 'lightgrey',
		borderRadius: 4,
	},
});
