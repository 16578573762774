import { createSlice } from '@reduxjs/toolkit';

const initialState: { appState: boolean } = {
  appState: 1,
};

export const appSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    appStateChanged: (state) => {
      state.appState = true;
      state.appState = false;
    },
  },
});

export const { appStateChanged } = appSlice.actions;

export default appSlice.reducer;
