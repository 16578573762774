import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import AllData from '../screens/all-data';
import EditReadings from '../screens/readings/edit-reading';

const Stack = createNativeStackNavigator();
export const ReadingsStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        contentStyle: {
          backgroundColor: 'white',
        },
      }}>
      <Stack.Screen
        options={{}}
        name='All Readings'
        component={AllData}
      />
      <Stack.Screen
        options={{}}
        name='AddReadings'
        component={EditReadings}
      />
      <Stack.Screen
        options={{}}
        name='EditReadings'
        component={EditReadings}
      />
    </Stack.Navigator>
  );
};
