import { createSlice } from '@reduxjs/toolkit';

const initialState: { 
    meterReadings: any[] ;
    averageReadings: any[] ;
    subTitle:string;
    date:string;
    num:number;
} = {
  meterReadings: [],
  averageReadings: [],
  subTitle:'daily',
  date:'',
  num:0
};

export const graphSlice = createSlice({
  name: 'graphs',
  initialState,
  reducers: {
    setmeterReadings: (state,action) => {
        return {...state,meterReadings:action.payload};

    },
    setaverageReadings: (state,action) => {
        return {...state,averageReadings:action.payload};

    },
    setsubTitle: (state,action) => {
        return {...state,subTitle:action.payload};

    },
    setDate: (state,action) => {
        return {...state,date:action.payload};

    },
    setNum: (state,action) => {
        return {...state,num:action.payload};

    },
  },
});

export const { setaverageReadings,setmeterReadings,setsubTitle ,setDate,setNum} = graphSlice.actions;

export default graphSlice.reducer;
