
import React,{ Pressable } from 'react-native';
import { IconButton,ActivityIndicator } from 'react-native-paper';

const CustomIconButton = ({
  icon,
  pressFun,
  isLoading,
  disabled,
  iconSize,
}: {
  icon: string;
  disabled: boolean;
  isLoading?: boolean;
  iconSize?: number;
  pressFun: () => void;
}) => (
  <Pressable
    onPress={pressFun}
    {...{ disabled }}
    style={{
      zIndex: 10,
    }}
  >
    {!isLoading ? (
      <IconButton
        onPress={pressFun}
        {...{ icon, disabled }}
        size={iconSize || 20}
      />
    ) : (
      <ActivityIndicator />
    )}
  </Pressable>
);


export default CustomIconButton